@use '@mfe/shared-css/global' as *;
.footerContainer {
  display: flex;
  background-color: color(gray_800);
  padding: 60px 32px;
  gap: 24px;

  //keeps the footer at the bottom of the page
  margin-top: auto;

  span,
  a,
  a:visited {
    color: color(white);
  }

  a:hover {
    color: color(gray_300);
  }

  @include screenSize('medium') {
    flex-direction: column;
    padding: 32px;
    gap: 32px;
  }

  @include screenSize('small') {
    align-items: center;
    gap: 32px;
    padding: 32px 24px;
  }

  @include screenSize('extraSmall') {
    padding: 32px 21px;
  }
}

.footerLegalAndLogo {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include screenSize('small') {
    align-items: center;
  }

  .footerLegal {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include screenSize('small') {
      flex-direction: column;
      gap: 16px;
      text-align: center;
    }
  }
}

.footerButtonsContainer {
  display: inline-flex;
  margin-left: auto;

  a:first-child {
    margin-right: 16px;
  }

  @include screenSize('medium') {
    display: flex;
    margin-right: auto;
    margin-left: 0;
  }

  @include screenSize('small') {
    margin-left: auto;
    margin-right: auto;
  }
}

$size: 40px;
.footerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: color(gray_700);

  svg {
    color: color(gray_300);
  }

  &:hover {
    svg {
      color: color(gray_400);
    }
  }
}
