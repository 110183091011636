@use '@mfe/shared-css/global' as *;

.button {
  @media screen and (max-width: $mdMin) {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media screen and (min-width: $mdMin) {
    padding: 4 24px;
  }
}
