@use '@mfe/shared-css/global' as *;

.wrapper {
  @media only screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media only screen and (min-width: 992px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.container {
  display: grid;
  gap: 24px;

  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 992px) {
    gap: 32px;
  }
}

.alertSection {
  > * + * {
    margin-top: 24px;
  }

  div[role='alert'] {
    box-sizing: border-box;
    background-color: white;
  }

  @media not screen and (min-width: 768px) {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.usageGrid {
  display: grid;
  grid-auto-rows: minmax(0, auto);
  gap: 8px;

  @media only screen and (min-width: 768px) {
    gap: 24px;
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: 400px 1fr;
  }
}

.rightColumn {
  display: grid;
  gap: 8px;

  // add z-index to display above summary box shadow
  // as a child of usageGrid it alread has stacking context
  // but added position: relative for safety
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    gap: 24px;
  }

  @media only screen and (min-width: 992px) {
    grid-column: 2;
    grid-row: 1 / span 3;
    align-self: flex-start;
  }

  // shieldAdContainer should not have margin bottom set in the first place
  > [class*='shieldAdContainer'],
  > [class*='chartLoadingContainer'] {
    margin-bottom: 0;
  }
}

.usageChartContainer {
  background: white;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    border-radius: 16px;
  }
}

.streamOnMargin {
  @include screenSize('extraSmall') {
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }
}

.faq {
  padding: 0px 24px;

  @include screenSize('medium') {
    padding: 0px 24px 24px 24px;
  }
}
