@use '@mfe/shared-css/global' as *;
.cardStyle {
  width: 100%;
  display: block;
  background-color: color(white);
  min-width: 320px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @include screenSize('small') {
    box-shadow: none;
    border-radius: 0;
  }
}

.divider {
  border-bottom: 1px solid color(gray_200);
}

.streamOnTitle {
  font-family: 'Source Sans Pro';
  font-size: 20px;
  padding: 24px 12px 24px 24px;
}

.streamOnBody {
  @include shimmerAnimate;
  margin: 12px 0;
  height: 16px;
  border-radius: 5px;
}
