@use '@mfe/shared-css/global' as *;

.subheading {
  margin-top: 32px;
  margin-bottom: 24px;
}

.applicationId > p + p {
  margin-top: 24px;
}

.footer {
  padding: 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;

  button {
    min-width: 310px;
  }

  a {
    text-decoration: none;
  }

  @include query-above($smMax) {
    border-top: 1px solid color(gray_200);
  }
}

.i-need-to-qulify-link {
  display: block;
  padding: 8px;
  width: 100%;
}
