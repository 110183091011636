@use '@mfe/shared-css/global' as *;

// Page layout
.page {
  a:first-of-type {
    text-wrap-mode: nowrap;
  }
  margin-bottom: 72px;

  [class*='headerWithBackNavWrapper'] {
    @include query-below($lgMin) {
      background-color: color(white);
    }
  }

  @include query-below($lgMin) {
    background-color: color(white);
  }
}

.header {
  [data-cy='navigation-header-layout'] {
    margin-bottom: 0;
  }

  [data-cy='steps-container'] {
    margin-bottom: 40px;
    margin-inline: 32px;
    padding-inline: 0;

    @include query-below($smMax) {
      margin-inline: 16px;
      padding-inline: 0;
    }
  }
}

.content {
  display: flex;
  gap: 24px;
  margin-inline: auto;
  max-width: 1248px;
  padding-inline: 32px;
  @include query-below($mdMax) {
    padding: 0;
  }
}

.leftCard {
  width: 100%;
  flex: 2;
  height: fit-content;
}

.rightCard {
  width: 100%;
  flex: 1;
  height: fit-content;
  position: relative;

  @include applyTopGradientBorder();
}

// Disconnection cart
.icon {
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
  }
}

.etfFee {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid color(gray_200);
}

.loadingIcon {
  @include shimmerAnimate;
  border-radius: 4px;
  min-width: 40px;
  height: 40px;
}

.loadingName {
  @include shimmerAnimate;
  height: 24px;
  width: 100%;
  border-radius: 4px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: color(gray_200);
  border: none;
}

.loadingRow {
  @include shimmerAnimate;
  height: 16px;
  width: 100%;
  border-radius: 4px;
}

// Collapsible disconnection cart
.summaryBackground {
  margin: 0;
  padding: 0;
  background-color: color(white);
  border-bottom: 1px solid color(gray_200);

  [data-cy='shopping-cart-icon'] {
    margin-right: 4px;
  }

  [data-cy='chevron'] {
    margin-right: 24px;

    @include query-below($xsMax) {
      margin-right: 16px;
    }
  }
}

%horizontalSpacing {
  padding: 16px 24px;
  @include query-below($xsMax) {
    padding: 16px;
  }
}

.summaryContent {
  @extend %horizontalSpacing;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  [class*='loadingRow'] {
    width: 120px;
  }

  span[class*='beam-font'] {
    text-wrap-mode: nowrap;
  }

  & > div {
    display: flex;
    align-items: center;
  }
}

.cartDetails {
  @extend %horizontalSpacing;
  padding-top: 24px;
}

// Card footer
.footer {
  border-top: 1px solid color(gray_200);
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  button[class*='primary'] {
    min-width: 224px;
  }

  @include query-below($smMax) {
    flex-direction: column-reverse;
    align-items: center;
    button {
      max-width: 400px;
      width: 100%;
    }
  }
}
