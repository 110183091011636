@use '@mfe/shared-css/global' as *;

.container {
  width: 612px;
  height: 520px;

  padding: 96px 48px;

  @include query-below($xlMin) {
    max-width: 714px;
  }

  @include query-below($lgMin) {
    max-width: 522px;
  }

  @include query-below($smMax) {
    width: 100%;
    height: 100%;
  }
}

.description {
  max-width: 358px;
}

.phoneNumber {
  color: color(blue_600);
  text-decoration: underline;
}

.backToMyViasat {
  max-width: 223px;

  @include query-below($smMax) {
    max-width: 400px;
  }
}
