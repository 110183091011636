@use '@vst/beam/sass/utils/colors';
@use 'sass:map';

.ring {
  display: inline-block;
  margin-right: 8px;
  color: map.get(colors.$raw-colors, 'gray_600');
  vertical-align: middle;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.ring circle {
  fill: none;
  stroke: map.get(colors.$raw-colors, 'gray_600');
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc(2s * 0.75) ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
}
