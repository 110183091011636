@use '@mfe/shared-css/global' as *;

.layout-container {
  display: flex;
  gap: 16px;

  @include screenSize('small') {
    flex-direction: column;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selection-card {
  width: 100%;
  padding: 0;
  [class*='beam-radio-button'] {
    width: 100%;
  }
  > div {
    padding: 0;
  }
  & > [class*='beam-radio-button'] input + label {
    width: 100%;
    margin: 0;
  }
}
