@use '@mfe/shared-css/global' as *;

.signoutButton {
  width: 100%;
  margin-right: 24px;
  @include screenSize('medium') {
    width: 288px;
    margin: 16px auto 0 auto;
  }
  @include screenSize('extraSmall') {
    margin: 16px auto 0 auto;
  }
}
