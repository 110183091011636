@use '@mfe/shared-css/global' as *;

.schedule-install-page-header {
  margin-top: 0px;
  margin-bottom: 8px;

  @include query-above($mdMin) {
    margin-block: 40px;
  }
}

.page-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;

  max-width: 1248px;

  margin: 0 auto 72px;
  @include query-below(1330px) {
    margin: 0 32px 72px;
  }

  @include query-below($mdMax) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include query-below($smMax) {
    margin: 0 0 8px;
    gap: 8px;
  }
}

.form-container {
  align-self: start;
  width: 100%;

  @include query-below($smMax) {
    border-radius: 0;
  }
}

.mobile-submit-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    max-width: 400px;
    width: 100%;
    align-self: center;
  }

  a {
    align-self: center;
  }
}

.no-appointments-container {
  hr {
    height: 1px;
    border: none;
    background-color: color(gray_200);
    margin: 0;
  }

  button {
    display: flex;
    min-width: 224px;
  }
}

.no-appointments-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
