@use '@mfe/shared-css/global' as *;

.imageContainer {
  background-image: url(~images/largeDisneyBanner.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  width: 100vw;
  max-width: 1184px;
  height: 120px;
  margin-bottom: 32px;
  color: white;
  gap: 4px;
  padding: 40px 32px;
  margin-bottom: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mdMin) {
    justify-content: center;
    border-radius: 0;
    height: 88px;
    padding: 16px 24px;
    margin-bottom: 8px;
  }
}
