.on-card-container {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.on-cart-container {
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.title-add-on-with-icon {
  display: flex;
  gap: 8px;
  align-items: center;
}
