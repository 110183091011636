@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  @media screen and (max-height: 800px) {
    min-height: 800px;
  }
}

.header {
  display: flex;
  flex: 0 0 76px;
  padding-left: 5%;
  padding-right: 5%;
  > div {
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 12px;
    @include query-below($mdMax) {
      justify-content: center;
    }
  }
}

.body {
  background-color: transparent;
  max-width: 480px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  @include query-below($smMin) {
    padding: 0 16px;
  }
}
