@use '@mfe/shared-css/global' as *;

.terms-notice {
  & > * + * {
    margin-left: 4px;
  }
}

.modalContent {
  li > p {
    margin-bottom: 0;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 18px;

    @include query-below($smMax) {
      padding-inline-start: 24px;
    }
  }
}

.table {
  margin-bottom: 18px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid color(gray_400);
  text-align: left;

  td,
  th {
    border: 1px solid color(gray_400);
    padding: 10px 20px;
  }

  th {
    background-color: color(gray_200);
  }

  tr:nth-child(even) td {
    background-color: color(white);
  }

  tr:nth-child(odd) td {
    background-color: color(gray_100);
  }
}
