@use '@mfe/shared-css/global' as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid color(gray_200);
  padding: 18px 24px;
}

.container {
  [class*='beam-empty-state__outer'] {
    padding: 48px;

    @include screenSize('small') {
      padding: 24px;
    }

    @include screenSize('extraSmall') {
      padding: 24px 16px;
    }
  }
}
