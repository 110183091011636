@use '@mfe/shared-css/global' as *;

.iconContainer {
  margin-bottom: 48px;

  @media screen and (max-width: $smMax) {
    margin-bottom: 32px;
  }

  svg {
    width: 100px;
    height: 100px;
  }
}
