@use '@mfe/shared-css/global' as *;

.wrapper {
  text-align: center;
  @include query-below($smMax) {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
  }
}

.content {
  margin: 64px 80px 48px 80px;
  height: 328px;
  overflow-y: scroll;
  text-align: center;

  @include query-below($smMax) {
    background-color: color(white);
    margin: 0;
    padding: 0 24px;
    flex: 1;
  }
}

.footer {
  background-color: color(gray_100);
  padding: 32px;
  border-top: 1px solid color(gray_200);

  @include query-below($smMax) {
    padding: 24px 16px;
    button {
      max-width: 343px;
    }
  }
  @include query-above($smMax) {
    border-radius: 0 0 16px 16px;
  }
}

.close-button-wrapper {
  margin-top: 28px;
  margin-right: 28px;
  display: flex;
  justify-content: flex-end;
  @include query-below($smMax) {
    background-color: color(white);
    margin: 0;
    padding-bottom: 64px;
  }
}

.close {
  background-color: color(white);
  border: none;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  @include query-below($smMax) {
    margin: 32px 24px 0 0;
  }
}

.spacing {
  display: none;

  @include query-below($smMax) {
    background-color: color(white);
    display: block;
    height: 104px;
  }
}
