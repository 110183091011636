.wrapper {
  padding: 40px 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 40px 32px 80px;
  }
}

.container {
  > * + * {
    margin-block-start: 48px;
  }
}

.content {
  > * + * {
    margin-block-start: 12px;
  }
}

.buttons {
  display: grid;
  gap: 16px;
  max-width: 400px;
  margin-inline: auto;

  button {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    max-width: none;

    button {
      width: 200px;
      max-width: 100%;
    }
  }
}
