@use '@mfe/shared-css/global' as *;

.noPlansPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  gap: 48px;
  text-align: center;
  max-width: 1248px;

  > svg {
    width: 360px;
    height: 210px;

    @include query-below($smMax) {
      width: 288px;
      height: 168px;
    }

    @include query-below($smMin) {
      height: 156px;
    }
  }
}

.button {
  width: 100%;

  @include query-above(575px) {
    > * {
      max-width: 200px;
    }
  }
}
