.selection-card {
  width: 100%;
  [class*='beam-radio-button'] {
    width: 100%;
  }
  > div {
    padding: 0;
  }
  div > [class*='beam-radio-button'] input + label {
    width: 100%;
    margin: 0;
  }
}
