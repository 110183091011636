@mixin screenSize($size) {
  $xsMax: '375px';
  $smMax: '767px';
  $mdMax: '991px';
  $lgMax: '1199px';

  @if $size== 'extraSmall' {
    @media (max-width: $xsMax) {
      @content;
    }
  }

  @if $size== 'small' {
    @media (max-width: $smMax) {
      @content;
    }
  }

  @if $size== 'medium' {
    @media (max-width: $mdMax) {
      @content;
    }
  }

  @if $size== 'large' {
    @media (max-width: $lgMax) {
      @content;
    }
  }
}
