@use '@mfe/shared-css/global' as *;

.extendedContainer {
  width: 100%;
  background-color: white;
  padding: 16px;
  align-items: center;
  border-top: 1px solid color(gray_200);
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.dropdownContainer {
  display: flex;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  margin-bottom: 24px;
  @include screenSize('small') {
    width: calc(100% - 48px);
    margin: 0 24px 24px 24px;
  }
}

.dropdownContainerOpen {
  @extend .dropdownContainer;
  flex-direction: column;
}

.dropdownMain {
  width: 100%;
  border: none;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 16px;
}

.dropdownMainOpen {
  border: none;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 16px;
}

.rotate180 {
  transform: rotate(180deg);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
