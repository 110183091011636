@use '@mfe/shared-css/global' as *;

.header *,
.header *::before,
.header *::after {
  box-sizing: border-box;
}

$iconSize: 50px;
$headerHeight: 50px;

.header {
  position: fixed;
  inset: auto 0;
  z-index: 1;
  background-color: color(white);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: $headerHeight;
  border-bottom: 1px solid color(gray_200);

  // Used to create an element at the end of header so that
  // The title can be centered between our back icon and
  // this invisible element
  &::after {
    content: '';
    width: $iconSize;
    height: $iconSize;
  }
}

.iconContainer {
  width: $iconSize;
  height: $iconSize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  flex: 1;
  text-align: center;
}

// Used so elements don't get positioned under nav bar
.spacer {
  height: $headerHeight;
}
