@use '@mfe/shared-css/global' as *;

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 160px;
  min-height: 100vh;
  @include query-below($smMax) {
    gap: 80px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
  flex: 0 0 76px;
  a {
    cursor: pointer;
    z-index: 100;
  }

  @include query-below($mdMax) {
    justify-content: center;
    padding-left: 0;
  }
}

.empty-state {
  margin: auto 0;

  svg {
    @include query-below($smMax) {
      width: 305px;
      height: 223px;
    }
  }
  p {
    z-index: 100;
  }

  div:nth-of-type(2) {
    @media screen and (min-width: 720px) and (max-width: 767px) {
      width: 100%;
    }
    place-items: center;

    button {
      max-width: 400px;
    }
  }

  @include query-below($smMax) {
    padding: 24px;
  }

  @include query-below($xsMax) {
    padding: 16px;
  }
}

.customer-support-tel-link {
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}
