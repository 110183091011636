@use '@mfe/shared-css/global' as *;

.gridSurface {
  flex-grow: 1;
}

.gridContainer {
  padding: 0;

  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 24px;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @include query-below($smMax) {
    grid-template-columns: 1fr;
  }
}

.separator {
  border-bottom: 1px solid color(gray_200);
  border-top: 1px solid color(gray_200);
}

.teal-color {
  div[data-cy='attribute-titleAddOns'] {
    color: color(teal_600);
  }
}

.loading {
  @include shimmerAnimate;
  width: 100%;
  border-radius: 8px;
}

.loadingCharacteristics {
  @extend .loading;
  height: 60px;
}

.loadingPrice {
  @extend .loading;
  height: 53.33px;
}

.loadingButton {
  @extend .loading;
  height: 48px;
  border-radius: 1000px;
}

.loadingLabel {
  @extend .loading;
  height: 98px;
}
