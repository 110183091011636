@use '@mfe/shared-css/global' as *;

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 32px 32px 80px;
  gap: 32px;

  @include screenSize('small') {
    gap: 0px;
  }

  @include screenSize('medium') {
    padding: 32px;
  }

  @include screenSize('small') {
    padding: 0;
    overflow-x: hidden;
    align-items: flex-start;
  }
}

.alertContainer {
  width: 100%;
  max-width: 1248px;
  min-width: 320px;

  > * + * {
    margin-top: 24px;
  }

  [role='alert'] {
    background-color: color(white);
  }

  @include screenSize('small') {
    padding: 24px;
    width: unset;
    align-self: center;
  }
}

.content {
  display: grid;
  grid-template-areas: 'runDiagnostics infoContainer' 'commonQuestions infoContainer' '. infoContainer';
  grid-template-columns: max-content;
  width: 100%;
  max-width: 1248px;
  grid-gap: 24px;
  @include screenSize('medium') {
    grid-template-columns: auto;
    grid-template-areas: 'runDiagnostics' 'infoContainer' 'commonQuestions' 'mobileAd';
  }
  @include screenSize('small') {
    grid-gap: 8px;
  }
}

.runDiagnostics {
  grid-area: runDiagnostics;
  display: flex;
  gap: 24px;
  flex-direction: column;
  @include screenSize('small') {
    gap: 8px;
  }
}

.commonQuestions {
  grid-area: commonQuestions;
}

.mobileAd {
  grid-area: mobileAd;
}

.mobileHelp {
  grid-area: mobileHelp;
}

%cardsSpacing > * + * {
  margin-top: 24px;
  @include screenSize('small') {
    margin-top: 8px;
  }
}

.infoContainer {
  grid-area: infoContainer;
  width: 100%;
  @extend %cardsSpacing;
}

.helpCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  @include screenSize('large') {
    flex-direction: column;
  }
  @include screenSize('small') {
    gap: 8px;
  }
}

.helpCardContainer {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 24px;
  gap: 24px;

  @media screen and (min-width: $xlMin) {
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
  }
}

.helpCardTextLink {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  justify-content: space-between;

  @media screen and (min-width: $xlMin) {
    flex-direction: column;
    align-items: flex-start;
    flex: 0;
  }

  @media screen and (max-width: $smMax) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.titleLoading {
  @include shimmerAnimate;
  height: 28px;
  width: 136px;
  border-radius: 4px;
}
