@use '@mfe/shared-css/global' as *;

.wrapper {
  @include query-above($mdMin) {
    padding-inline: 32px;
  }
}

.voiceHeader {
  & > div {
    margin-bottom: 0;
    @include query-above($mdMin) {
      grid-template-columns: 200px 1fr 200px;
    }
  }
  padding: 24px;

  @include query-below($xsMax) {
    padding: 16px;
  }
}

.container {
  @include query-above($mdMin) {
    display: flex;
    gap: 24px;
    max-width: 1248px;
    margin-inline: auto;
    margin-bottom: 72px;
  }
}

.leftColumn {
  flex: 2;
  border-radius: 16px;
}

%icon {
  height: 24px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: color(white);
}

.content {
  @include query-above($mdMin) {
    border-radius: var(--beam-surface--radius_16px);
  }

  padding-inline: 48px;
  padding-top: 48px;
  padding-bottom: 0;

  @media screen and (max-width: 719px) {
    padding: 24px;
  }
}

.divider {
  height: 1px;
  border: none;
  background-color: var(--beam-color-gray_200);

  margin-block: 0;
  margin-inline: calc(var(--beam--component-x-paddings) * -2);
}

.mainActionsContainer {
  @media screen and (max-width: 719px) {
    padding-inline: 16px;
    flex-direction: column-reverse;
  }
}

.backButtonContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: 719px) {
    justify-content: space-around;
    margin-bottom: 24px;
    &:not(:empty) {
      margin-top: 12px;
    }
  }
}

.stepIcon {
  @extend %icon;
  background: color(gray_600);
}

.validIcon {
  @extend %icon;
  background: color(green_600);
}

.nextButton {
  display: flex;
  align-self: flex-end;
  margin: 40px 0 24px;
}

.sectionHeader {
  display: flex;
  align-items: center;
}

.sectionHeaderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.transitionWrapper {
  margin-top: 24px;
}

.stepOneSectionAlert {
  > * + * {
    margin-left: 2px;
  }
}

.stepFourContent {
  width: 100%;
}

.selectionCards {
  gap: 16px;
  width: 100%;
  display: grid;

  [class*='beam-selection-card'] {
    padding: 0;
    display: grid;
  }

  [class*='beam-radio-group__button'] {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-row-gap: 6px;

    @include query-below($xlMin) {
      grid-template-columns: 1fr;
    }
  }

  [class*='beam-selection-card'] input + label {
    display: flex;
    margin: 0;
    width: auto;
    min-height: 100%;
  }
}

.formContent {
  display: flex;
  flex-direction: column;
}

.formGroup {
  gap: 16px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.helperText {
  margin: 8px 0 12px;
  align-items: flex-start;
  flex-wrap: nowrap;

  svg {
    margin-top: 2px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-block: 24px;

  @include query-above($mdMin) {
    button[type='submit'] {
      max-width: 223px;
    }
  }

  @include query-below($mdMin) {
    flex-direction: column-reverse;
    button[type='submit'] {
      max-width: 400px;
    }
  }
}

.zipCodeButtons {
  display: flex;
  align-self: flex-end;
  margin-top: 24px;
  gap: 8px;

  @media screen and (max-width: 375px) {
    align-self: auto;
    flex-direction: column-reverse;

    button[type='submit'] {
      width: 100%;
    }
  }
}

.callerId {
  width: auto;
}

.loading-scrub-address-modal {
  width: 600px;
  height: auto;
  min-height: unset;
  height: 240px;
  :global {
    .beam-modal__wrap-actions {
      border-top: none;
    }
  }

  @include query-above($smMax) {
    margin-top: 12px;
  }

  @include query-below($smMax) {
    width: 100%;
    height: auto;
  }

  p,
  [class*='beam-modal__body'] {
    padding: 0;
    margin: 0;
  }

  [class*='beam-spinner-wrap'] {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
  }

  [class*='beam-spinner'] {
    margin-bottom: 0;
  }
}

.modal-body {
  text-align: center;
}

.incorrect-address-modal {
  min-height: unset;
}
