.current-bill-date-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.current-bill-date {
  display: flex;
  gap: 8px;
}
