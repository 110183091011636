@use '@mfe/shared-css/global' as *;

.smallLoading {
  display: flex;
  flex-direction: column;
  background-color: color(white);
  justify-content: center;

  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
  }
}

.viewStatementLoading {
  @include shimmerAnimate;
  width: 140px;
  height: 16px;
  border-radius: 5px;
}
