@use '@mfe/shared-css/global' as *;

.articleCardLayout {
  display: flex;
  align-items: center;
  gap: 24px;

  padding: 24px;

  border-radius: 16px;
  background: color(white);

  @include screenSize('small') {
    gap: 16px;
    border-radius: 0;
  }
}

.articleCardLayoutPortrait {
  flex-direction: column;
}

.articleCardImage {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 190px;
  flex-shrink: 0;
}

.articleCardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
