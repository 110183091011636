@use '@mfe/shared-css/global' as *;

.balanceCard,
.error {
  background: linear-gradient(
    62.02deg,
    color(blue_700) 17.68%,
    color(teal_600) 151.32%
  );
  box-sizing: border-box;
  width: 400px;
  max-width: 400px;
  padding: 24px;
  border-radius: 16px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @include screenSize('medium') {
    width: 100%;
    max-width: 100%;
  }
  @include screenSize('small') {
    border-radius: 0px;
  }
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.error {
  padding: 72px 24px;
  p {
    margin-bottom: 0;
  }

  @include screenSize('extraSmall') {
    padding: 72px 16px;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: inherit;

  svg {
    color: color(white);
  }
}

.autoPayContainer {
  align-items: center;
  padding: 0px 24px 24px 24px;
}

.autoPay {
  border-radius: 8px;
  padding: 16px;
  background-color: color(blue_800);
}

.balanceFooter {
  border-top: 1px solid color(blue_800);
  padding-top: 24px;
  text-align: center;

  button {
    max-width: 400px;
  }
}
