@use '@mfe/shared-css/global' as *;

.UsageChartWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.TitleSection {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid color(gray_100);

  @media screen and (min-width: $mdMin) {
    flex-direction: row;
  }
}

.BillCycleDropdown {
  padding: 0 24px;
  margin-bottom: 24px;

  @media screen and (min-width: $mdMin) {
    padding: 0;
    margin-left: auto;
    margin-right: 24px;
    margin-top: 24px;
    align-content: center;
  }
}

.UsageChart {
  padding: 0 24px 24px 24px;
}

.NoDataUsed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 72px 0 48px;
}
