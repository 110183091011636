@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: color(white);
  &.blue {
    background-color: color(blue_100);
  }
  @media screen and (max-width: $smMax) {
    border-radius: 0;
  }
}

.sub-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  align-items: center;
  @media screen and (max-width: $lgMax) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.buttons {
  display: flex;
  align-items: center;
  > * {
    min-width: 100px;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.logo-container {
  display: flex;
  align-items: center;
}
