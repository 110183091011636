@use '@mfe/shared-css/global' as *;

.content {
  padding: 0 40px 32px;
  margin-top: 24px;
  flex-grow: 1;

  &.has-vertical-scroll {
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  &.centered {
    display: flex;
    align-items: center;
  }

  @include query-below($smMax) {
    padding: 0 16px 40px;
    margin-inline: auto;
    max-width: 400px;
  }
}
