@use '@mfe/shared-css/global' as *;

.DonutChartWrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @media screen and (min-width: $mdMin) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
  }

  :global(.highcharts-container) {
    max-width: 100%;
    height: auto !important;
    aspect-ratio: 1;
  }
}

.LegendWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-self: stretch;

  @media screen and (min-width: $mdMin) {
    width: 252px;
  }

  @media screen and (min-width: $lgMin) {
    width: 223px;
  }
}
