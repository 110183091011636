.container {
  padding-top: 24px;
  border-top: 1px solid color(gray_200);
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.one-time-fees {
  display: flex;
  align-items: center;
}
