@use '@mfe/shared-css/global' as *;

.container {
  margin-top: 40px;
  @include query-below($smMax) {
    margin-bottom: 40px;
  }
}

.helper-text {
  margin: 16px 0px;
}

.inputs-container {
  display: flex;
  gap: 20px;
  @include query-below($smMax) {
    flex-direction: column;
  }
}

.name-input {
  flex: 1;
  width: 100%;
}

.phone-input {
  flex: 1;
  width: 100%;
}
