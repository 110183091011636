@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 612px;
  margin: 40px auto;
  height: 520px;

  @include query-below($xlMin) {
    width: 100%;
  }
}

.error {
  svg {
    color: color(alert_400);
  }
}

.addon-unavailable-wrapper {
  display: flex;
  flex: 2;
  border-radius: 16px;
  margin: 48px auto;
  text-align: center;

  @include query-below($mdMin) {
    margin: 24px 24px;
  }
}

.addon-unavailable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
