@use '@mfe/shared-css/global' as *;

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  padding: 32px 24px;
  min-height: 400px;
  flex-grow: 1;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid color(gray_200);
  padding: 24px 16px;
}
