@use '@mfe/shared-css/global' as *;

// Max width of primary CTA on mobile + horizontal padding
$intermediaryBreakpoint: 448px;

// Page
.content {
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px;

  @include query-below($intermediaryBreakpoint) {
    padding: 24px;
  }
}

.errorAlert {
  div {
    align-items: center;
  }
}

.customerSupportCta {
  color: color(gray_800);
  display: flex;
  gap: 4px;
  font-size: 16px;
  a {
    text-decoration: none;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  border-top: 1px solid color(gray_200);

  button: {
    min-width: 224px;
  }
}

// Plan offers
.availablePlans {
  display: flex;
  justify-content: center;
  gap: 16px;

  @include query-below($mdMax) {
    flex-direction: column;
    align-items: center;
  }

  [class*='loadingLabel'] {
    display: none;
  }
}

.planConfirmationModal {
  [class*='beam-btn--primary'] {
    min-width: 200px;
  }
  [class*='beam-modal__body'] {
    padding-bottom: 0px;
    min-height: fit-content;
  }
}

// Retention offers
.retentionOffers {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

%retentionOfferLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding: 16px;
  border-radius: 8px;
  border: 1px solid color(gray_200);

  @include query-below($intermediaryBreakpoint) {
    flex-direction: column;
    align-items: flex-start;

    [class*='beam-btn--secondary'] {
      width: 100%;
    }
  }
}

.retentionOfferLoading {
  @extend %retentionOfferLayout;

  [id='details'] {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 200px;

    @include query-below($intermediaryBreakpoint) {
      width: 100%;
    }

    [id='name'],
    [id='description'] {
      @include shimmerAnimate;
      border-radius: 8px;
      width: 100%;
      max-width: 500px;
      height: 24px;
    }
  }

  [id='button'] {
    @include shimmerAnimate;
    border-radius: 32px;
    width: 176px;
    height: 40px;
    align-self: center;
    @include query-below($intermediaryBreakpoint) {
      width: 100%;
    }
  }
}

.retentionOffer {
  @extend %retentionOfferLayout;

  button {
    width: 176px;
    height: fit-content;
  }
}

.retentionOfferDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.loadingModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.loadingModalContent {
  display: flex;
  flex-direction: column;
  height: 380px;
  width: 600px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  border-radius: 15px;
  @include screenSize('small') {
    margin: auto 16px auto 16px;
  }
}

.confirmationModal {
  display: flex;
  text-align: center;
  gap: 8px;
  border: 1px solid color(teal_600);
  border-radius: 8px;
  padding: 16px;
  background-color: rgba(color(teal_200), 0.1);
}

.borderBottom {
  border-bottom: 1px solid color(gray_200);
}
