@use '@mfe/shared-css/global' as *;

.form {
  padding: 48px;

  > * + * {
    margin-top: 40px;
  }

  @include query-below($smMax) {
    padding: 24px;
  }

  @include query-below(calc(#{$xsMax} - 1px)) {
    padding: 16px;
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid color(gray_200);

  [type='submit']:only-child {
    margin-left: auto;
  }
}
