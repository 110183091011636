@use '@mfe/shared-css/global' as *;

.loading {
  @include query-below($smMax) {
    padding: 56px;
  }
  padding: 80px;
}

.mobile-loading-header {
  margin-top: 0;

  @include query-below($smMax) {
    border-top: 1px solid color(gray_200);
  }
}

.page-header {
  @include query-below($smMax) {
    margin-block: var(--collapsible-summary-height, 0px) 24px;
  }

  @include query-above($mdMin) {
    margin-top: calc(var(--collapsible-current-plan-height, 0px) + 40px);
  }
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include query-below(1312px) {
    margin: 0 32px;
  }
  @include query-below($smMax) {
    margin: 0 24px;
  }
  @include query-below($xsMax) {
    margin: 0 16px;
  }
}

.content {
  max-width: 1248px;
  width: 100%;
  display: flex;
  gap: 24px;
}

.current-plan-wrapper {
  & > div {
    position: sticky;
    top: 116px;
  }
}

.toggle-labels {
  min-width: 107px;
  align-self: center;
  max-height: 36px;
  padding: 8px 20px;

  @include query-below($smMax) {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 100;
  }
}
