.appStoreButton {
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  > img {
    width: 100%;
  }
}
