@use '@mfe/shared-css/global' as *;

.containerBlue {
  > div {
    @include shimmerAnimateBlue;
    border-radius: 4px;
  }
}

.containerWhite {
  > div {
    @include shimmerAnimate;
    border-radius: 4px;
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 24px 0;
}

.planName {
  width: 184px;
  height: 24px;
  margin-bottom: 4px;
}

.changePlanLink {
  width: 80px;
  height: 16px;
}

.dataRemaining {
  height: 36px;
}

.daysLeft {
  height: 24px;
  margin-top: 4px;
  margin-bottom: 24px;
}

.usageBar {
  height: 12px;
  margin-bottom: 24px;
}

.buyMoreHistory {
  height: 64px;
}
