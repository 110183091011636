@use '@mfe/shared-css/global' as *;

.legend {
  background-color: color(gray_100);
  padding: 4px 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  z-index: 0;
}

.wordSpacing::after {
  content: ' ';
}
