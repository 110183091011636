@use '@mfe/shared-css/global' as *;

.staticContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: color(white);
  border-bottom: 1px solid color(gray_200);
  @media screen and (max-width: $xsMax) {
    padding: 24px 16px;
  }
}

.staticContainerWithoutSms {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: color(white);
  border-radius: 0 0 16px 16px;
  @media screen and (max-width: $smMax) {
    border-radius: 0;
  }
  @media screen and (max-width: $xsMax) {
    padding: 24px 16px;
  }
}

.staticLineContainer {
  display: flex;
  margin-top: 4px;
  color: color(black);
}

.changeAddress {
  margin-top: 8px;
}

.subtitleContainer {
  display: flex;
  gap: 4px;
  color: color(black);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 8px 0;
}

.loading {
  @include shimmerAnimate;
  max-width: 350px;
  width: 100%;
  height: 24px;
  border-radius: 5px;
}
