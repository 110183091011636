@use '@mfe/shared-css/global' as *;

.containerCard {
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  background-color: color(white);
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  margin-left: 24px;
  height: min-content;
  @include screenSize('medium') {
    max-width: none;
    margin-left: 0;
    margin-top: 24px;
  }
  @include screenSize('small') {
    border-radius: 0;
    margin-top: 8px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 32px 24px 24px 24px;
  &:before {
    content: '';
    height: 8px;
    width: 100%;
    background: linear-gradient(90deg, color(blue_700), color(teal_500));
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  @include screenSize('small') {
    padding: 24px 16px 16px 16px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.title {
  color: color(gray_800);
  margin-top: 32px;

  @media screen and (max-width: $mdMin) {
    margin-top: 28px;
  }
}

.totalAmount {
  display: flex;
  flex-direction: column;
  color: color(gray_800);
  margin-bottom: 16px;
}

.imageAndText {
  display: flex;
  padding: 16px 0 24px 0;
  align-items: center;
  border-bottom: 1px solid color(gray_200);
}

.imageBackground {
  background-color: color(gray_100);
  border-radius: 12px;
  padding: 12px;
  margin-right: 16px;
}

.streamOnHub {
  margin-left: 16px;
}

.divider {
  background-color: color(gray_200);
  height: 1px;
  margin: 20px 0;

  @media screen and (max-width: $xsMax) {
    margin: 16px 0;
  }
}

.termsAndConditions {
  margin: 24px 0 12px 0;
  a {
    font-size: 14px;
    text-decoration: none;
  }
}

.disclosure {
  margin-bottom: 32px;
}

.currentBill {
  margin-bottom: 16px;

  @media screen and (max-width: $mdMin) {
    margin-bottom: 12px;
  }
}

.cartPricingContainer {
  display: flex;
  flex-direction: column;
}

.cartPricingStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid color(gray_200);
}

.crossout {
  color: color(gray_400);
  margin-left: auto;
}

.discount {
  margin-left: 8px;
}

.loading {
  @include shimmerAnimate;
  max-width: 80px;
  width: 100%;
  height: 24px;
  border-radius: 5px;
}
