@use '@mfe/shared-css/global' as *;

.alertsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1248px;
  min-width: 320px;
  margin-top: 24px;

  @media only screen and (min-width: 992px) {
    margin-top: 32px;
  }

  // If the ACP Banner and Notifications are both showing, adds space between
  &:not(:first-child) {
    margin-top: 24px;

    @include screenSize('small') {
      margin-top: unset;
    }
  }
  // Adds space between notifications
  > * {
    margin-bottom: 24px;
  }

  [role='alert'] {
    box-sizing: border-box;
    background-color: color(white);
    @include screenSize('small') {
      width: calc(100% - 24px - 24px);
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }

  @include screenSize('small') {
    // background-color: white;
    padding: 24px 24px 0 24px;
  }
}

// New design
.pageContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 32px 80px;

  @include screenSize('medium') {
    padding: 0 24px;
  }

  @include screenSize('small') {
    padding: 0;
    overflow-x: hidden;
    align-items: flex-start;
  }
}

.accountNumberLoading {
  @include shimmerAnimate;
  display: inline-block;
  width: 100px;
  height: 24px;
  border-radius: 4px;
}

%placeholder {
  background-color: lightgrey;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
}

.header {
  box-sizing: border-box;
  padding: 56px 0;
  width: 100%;
  max-width: 1248px;
  background-color: transparent;

  @include screenSize('small') {
    padding: 24px;
  }
}

.accountNumber {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

// Grid with 3 main areas that shift their order on mobile
.content {
  display: grid;
  grid-template-areas: 'balance infoContainer' 'commonQuestions infoContainer' 'mvAppAd infoContainer' '. infoContainer';
  grid-template-columns: max-content;
  width: 100%;
  max-width: 1248px;
  grid-gap: 24px;

  @include screenSize('medium') {
    grid-template-columns: auto;
    grid-template-areas: 'balance' 'infoContainer' 'commonQuestions' 'mvAppAd';
    grid-gap: 8px;
  }
}

.balance {
  grid-area: balance;
}

.questions {
  grid-area: commonQuestions;
}

.mvAppAd {
  grid-area: mvAppAd;
  width: 400px;
  @include screenSize('medium') {
    width: 100%;
  }
}

%cardsSpacing > * + * {
  margin-top: 24px;
  @include screenSize('medium') {
    margin-top: 8px;
  }
}

.infoContainer {
  grid-area: infoContainer;
  width: 100%;
  @extend %cardsSpacing;
}

.balancePlaceholder {
  @extend %placeholder;
  height: 336px;
  width: 400px;
  max-width: 400px;
  padding: 24px;

  @include screenSize('medium') {
    width: 100%;
    max-width: 100%;
  }
}

.planInfo {
  @extend %cardsSpacing;
}

.planInfoLoading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;

  @include screenSize('medium') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.leftLoading {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.iconLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  min-width: 40px;
  height: 40px;
}

.planNameLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  width: 100%;
  height: 28px;
}

.rightLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  width: 100%;
  max-width: 336px;
  height: 40px;
}

.planPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;

  @include screenSize('large') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.previewContent {
  display: flex;
  align-items: center;
  gap: 16px;
}

.iconBackground {
  background-color: color(teal_100);
  color: color(teal_600);
  border-radius: 8px;
  padding: 8px;
  height: 40px;
}

.inlineText {
  display: inline;
  & > * + * {
    margin-left: 4px;
  }
}

.actions {
  display: flex;
  gap: 16px;

  @include screenSize('small') {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  button {
    text-wrap: nowrap;
    width: 100%;
    max-width: 400px;
    align-self: center;
  }
}

.usageSummary {
  display: flex;
  flex-direction: column;
  padding: 24px;
  & > * + * {
    margin-top: 24px;
  }
}

.dataRemaining {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usageSummaryError {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.horizontalGroup {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;

  button {
    text-wrap: nowrap;
  }
}

.verticalGroup {
  width: 100%;
  & > * + * {
    margin-top: 8px;
  }
}

.dataRemainingLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  height: 36px;
  width: 100%;
}

.renewalDateLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  height: 24px;
  width: 100%;
}

.buttonLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  height: 40px;
  display: flex;
  flex: 1 0 184px;
}

.sectionLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  height: 60px;
  width: 100%;
}

//Articles section

.articleMargin {
  width: 100%;
  grid-column: 1/3;
  grid-row: 2;
  @include screenSize('large') {
    grid-column: 1/3;
    grid-row: 2;
  }
}

.streamOnMargin {
  width: 100%;
  grid-column: 1/3;
}

.addOnMargin {
  width: 100%;
  grid-column: 1/3;
  @include screenSize('large') {
    grid-column: 1;
  }
  @include screenSize('medium') {
    grid-column: 1;
  }
  @include screenSize('small') {
    padding: 0;
  }
}

.serviceCards {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
  grid-row: 2;
}

//Common Questions

.commonQuestionsMargin {
  grid-area: commonQuestions;
  width: 100%;
  max-width: 352px;
  grid-column: 1;
  grid-row: 2;
  padding: 0px 24px;

  @include screenSize('medium') {
    max-width: none;
    padding: 0;
    grid-row: -2;
  }

  @include screenSize('small') {
    width: calc(100vw - 24px - 24px);
    padding-inline: 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }
}

.accordionDescription {
  * + * {
    margin-top: 16px;
  }

  a + a {
    margin-top: 0px;
  }
}
