@use '@mfe/shared-css/global' as *;

.content-wrapper {
  padding-top: 48px;

  @include query-above($xlMin) {
    width: 824px;
    margin: 0 auto 72px auto;
  }

  @include query-below($lgMax) {
    margin: 0 32px 72px 32px;
  }

  @include query-below($smMax) {
    margin: 0;
    border-radius: 0;
    padding: 24px;
  }

  @include query-below($xsMax) {
    padding: 24px 16px;
  }
}

.form-content {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 40px 48px 48px 48px;
  @include query-below($smMax) {
    padding: 0px;
  }
}

.form-button {
  border-top: 1px solid color(gray_200);
  text-align: right;
  border-radius: 0px, 0px, 16px, 16px;
  padding: 24px 48px;
  @include query-below($lgMax) {
    text-align: center;
    button {
      width: 400px;
    }
  }
  @include query-below($smMax) {
    padding: 0;
    padding-top: 24px;
  }
}

.calendar-title {
  @include query-above($smMax) {
    margin-left: 48px;
  }

  @include query-below($smMax) {
    margin-bottom: 40px;
  }
}
