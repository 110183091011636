@use '@mfe/shared-css/global' as *;

.leftCardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  @include query-below($smMax) {
    padding: 24px;
  }
  @include query-below($xsMax) {
    padding: 16px;
  }
}

.contactInformation {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: color(gray_200);
  border: none;
}

.contactInfoCard {
  width: 100%;
  & > div:not(:first-child) {
    padding-top: 8px;
  }
}

.sectionAlert {
  & > div {
    display: block;
  }

  span:first-of-type {
    margin-right: 4px;
  }

  [class*='beam-section-alert__content'] {
    display: flex;
  }
}

.disclosure {
  display: flex;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
}

.icon {
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  svg {
    width: 24px;
    height: 24px;
  }
}

.unorderedList {
  padding: 0;
  margin: 0;
}
