@use '@mfe/shared-css/global' as *;

.cart-summary-container {
  position: relative;

  hr {
    height: 1px;
    width: 100%;
    border: none;
    background-color: color(gray_200);
    margin: 0;
  }

  & > *:first-child {
    padding: 0;
  }

  & > *:last-child {
    margin-top: 24px;
  }

  @include query-below($xlMin) {
    padding: 48px;
  }

  @include query-below($mdMin) {
    padding: 24px;
  }

  @include query-below($xsMax) {
    padding: 24px 16px;
  }
}
