@use '@mfe/shared-css/global' as *;

.buyMoreContainer {
  background-color: color(gray_100);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
