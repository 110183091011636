@use '@mfe/shared-css/global' as *;

.wrapper {
  width: 100%;

  @include query-below($smMax) {
    background-color: color(white);
  }

  @include query-above($smMax) {
    max-width: 576px;
    margin-block: 40px;
    margin-inline: auto;
  }
}

.gradient {
  height: 8px;
  background: linear-gradient(
    90deg,
    color(teal_600) 0.51%,
    color(green_300) 100.51%
  );

  @include query-above($smMax) {
    border-radius: 16px 16px 0 0;
  }
}

.card-layout {
  width: 100%;
  background-color: color(white);

  h4 {
    margin-bottom: 24px;
  }

  @include query-above($smMax) {
    border-radius: 0 0 16px 16px;
  }
}

.content {
  @include query-above($smMax) {
    padding: 32px 48px;
  }

  @include query-below($smMax) {
    padding: 32px 24px;
  }
}
