@use '@mfe/shared-css/global' as *;

.container {
  flex-grow: 1;

  @include query-below($mdMin) {
    display: flex;
    flex-direction: column;

    & > *:first-child {
      flex-grow: 1;
    }
  }
}
