@use '@mfe/shared-css/global' as *;

.buttonContainerMargin {
  margin-bottom: 24px;

  hr {
    height: 1px;
    border: none;
    background-color: color(gray_200);
    margin: 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 16px;
  background-color: color(gray_100);
  max-width: 400px;
}

.buttonPadding {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  width: 100%;
  border: none;
  padding: 16px;
}

.buttonOff {
  @extend .button;
  background-color: color(gray_100);
}

.buttonOn {
  @extend .button;
  border-radius: 8px;
  background-color: color(white);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
