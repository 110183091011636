@use '@mfe/shared-css/global' as *;

.SmsContainer {
  background-color: color(white);
  padding: 24px;
  border-radius: 0 0 16px 16px;

  @media screen and (max-width: $xsMax) {
    padding: 24px 16px;
  }
}

.phoneNumber {
  margin-bottom: 12px;
}

.linkPhoneNumber {
  margin-bottom: 12px;
}

.subtitleContainer {
  display: flex;
  gap: 4px;
}
