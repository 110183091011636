@use '@mfe/shared-css/global' as *;

.statementContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  padding-bottom: 32px;

  &:not(:last-child) {
    border-bottom: 1px solid color(gray_200);
  }
}

.statementRowContainer {
  display: flex;
  flex: 1;
  padding-bottom: 24px;
}

.headerContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
}

.body {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.link {
  padding: 0px;
  padding-right: 32px;
  background-color: transparent;
  border: none;

  & > p {
    cursor: pointer;
    color: color(blue_600);
    text-decoration: underline;
  }
}

.links {
  display: flex;
  flex: 1;
}
