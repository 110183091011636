@use '@mfe/shared-css/global' as *;

%labelsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.labelsWrapperBlue {
  @extend %labelsWrapper;
  span {
    color: color(white);
  }
}

.labelsWrapperWhite {
  @extend %labelsWrapper;
  span {
    color: color(gray_600);
  }
}

.usageType {
  display: flex;
  align-items: center;
}

%usageTypesWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.usageTypesWrapperBlue {
  @extend %usageTypesWrapper;
  span {
    color: color(white);
  }
}

.usageTypesWrapperWhite {
  @extend %usageTypesWrapper;
  span {
    color: color(gray_600);
  }
}

.usageTypeIndicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 4px;
}
