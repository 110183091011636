@use '@mfe/shared-css/global' as *;
.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 24px auto 72px auto;
  @include screenSize('medium') {
    width: 100%;
  }
  @include screenSize('small') {
    margin: 0;
  }
}

.headerMargin {
  max-width: 976px;
  width: 100%;
  margin: 24px 32px 0 32px;
  min-width: 320px;
  @include screenSize('medium') {
    width: 576px;
    margin: 24px auto 0 auto;
  }
  @include screenSize('small') {
    width: 100%;
    margin: 0;
  }
}

.bodyGrid {
  justify-content: center;
  background-color: color(gray_100);
  align-items: flex-start;
  display: grid;
  grid-template-columns: 369px 607px;
  padding-bottom: 72px;
  margin: 0 32px;
  min-width: 320px;
  @include screenSize('medium') {
    margin: 0 auto;
    width: 576px;
    grid-template-columns: 1fr;
    padding-bottom: 24px;
  }
  @include screenSize('small') {
    width: 100%;
  }
}

.sideNavContainer {
  margin-right: 24px;
}

.navMargin {
  width: 100%;
  margin-right: 24px;
  @include screenSize('medium') {
    margin: 0 32px;
  }
}

.versionContainer {
  margin: auto;
  @include screenSize('medium') {
    margin: 0px auto 8px auto;
  }
  @include screenSize('small') {
    margin: 8px auto 0px auto;
  }
}
.appAlertsContainer {
  display: grid;
  margin-bottom: 24px;
  gap: 24px;

  [role='alert'] {
    background-color: color(white);
  }

  @include screenSize('small') {
    padding: 16px;
    margin-bottom: 0;
  }
}
