@use '@mfe/shared-css/global' as *;

.alertSection {
  padding-bottom: 24px;
  @include screenSize('small') {
    background-color: white;
    padding: 16px;
    padding-bottom: 0px;
  }

  [role='alert'] {
    background-color: color(white);
  }
}
