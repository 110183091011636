@use '@mfe/shared-css/global' as *;

.streamOnCardStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: color(white);
  padding-bottom: 24px;
  width: 100%;
  max-width: 824px;
  min-width: 320px;
  @include screenSize('medium') {
    max-width: none;
  }
}

.streamOnTitle {
  border-bottom: 1px solid color(gray_200);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.streamOnTitleLeft {
  display: flex;
  align-items: center;
  padding: 24px;
  @include screenSize('small') {
    padding: 16px 0 0 16px;
  }
}

.streamOnTitleStatus {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 24px;
  @include screenSize('small') {
    margin: 16px;
  }
  &.pending {
    background-color: rgba(159, 175, 188, 0.25);
  }
  &.info {
    background-color: rgba(100, 206, 251, 0.25);
  }
  &.active {
    background-color: rgba(204, 236, 106, 0.25);
  }
  &.error {
    background-color: rgba(250, 114, 114, 0.25);
  }
}

.streamOnFooter {
  display: flex;
  padding: 24px 24px 0 24px;
  align-items: center;
  @include screenSize('small') {
    padding: 24px 16px 0 16px;
  }

  &.timeout {
    justify-content: center;
  }
}

.streamFooterButton {
  width: 400px;
  @include screenSize('extraSmall') {
    width: 100%;
  }
}

.streamHeader {
  padding: 24px 24px 0 24px;
  @include screenSize('small') {
    padding: 16px 16px 0 16px;
  }
  &.timeout {
    padding: 24px 80px 0 80px;
    @include screenSize('small') {
      padding: 16px 16px 0 16px;
    }
  }

  [class*='beam-empty-state__outer'] {
    padding: 48px;

    svg {
      color: color(black);
    }
    p {
      margin: 0;
    }
  }
}

.streamBody {
  padding: 0 24px 0 24px;
  flex-direction: column;
  @include screenSize('small') {
    padding: 0 16px 0 16px;
  }
}

.streamNotif {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgba(100, 206, 251, 0.25);
  a {
    text-decoration: none;
  }

  &.error {
    background-color: rgba(250, 114, 114, 0.25);
  }

  .infoText {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    padding: 0;
  }
}
.errorCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: color(white);
  padding-bottom: 24px;
  width: 100%;
  max-width: 824px;
  min-width: 320px;
  @include screenSize('medium') {
    max-width: none;
  }
}
