@use '@mfe/shared-css/global' as *;

.wrapper {
  @include query-above($mdMin) {
    padding-inline: 32px;
  }
}

.container {
  display: flex;
  gap: 24px;
  max-width: 1248px;
  margin-inline: auto;

  @include query-above($mdMin) {
    margin-bottom: 72px;
  }
}

.header {
  & > div {
    @include query-above($mdMin) {
      grid-template-columns: 200px 1fr 200px;
    }
  }
  padding: 24px;
  @include query-below($mdMin) {
    border-bottom: 8px solid color(gray_100);
  }
  @include query-below($xsMax) {
    padding: 16px;
  }
}

.aem-fragment-surface {
  align-self: flex-start;
  flex: 2;

  @include query-above($mdMin) {
    border-radius: 16px;
  }
}

#aem-fragment-container {
  padding: 0px 16px;
  @include query-below($xsMax) {
    padding: 0;
  }
}

.terms-container {
  padding: 16px 32px 40px;

  @include query-above($mdMin) {
    padding-inline: 40px;
  }
}

.submit-container {
  padding: 24px 16px;

  border-top: 1px solid color(gray_200);
  text-align: center;

  button {
    width: 100%;
    max-width: 400px;
  }

  @include query-above($xsMax) {
    padding: 24px;
  }

  @include query-above($mdMin) {
    text-align: right;
    padding: 24px 40px;

    button {
      width: auto;
      max-width: none;
    }
  }
}

.addon-unavailable-wrapper {
  display: flex;
  flex: 2;
  border-radius: 16px;
  margin: 48px auto;
  text-align: center;

  @include query-below($mdMin) {
    margin: 24px 24px;
  }
}

.addon-unavailable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
