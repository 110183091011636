@use '@vst/beam/sass/utils/colors';
@use 'sass:map';
@use '@mfe/shared-css/global' as *;

.iconContainer {
  margin-bottom: 48px;

  @media screen and (min-width: $mdMin) {
    svg {
      width: 300px;
      height: 175px;
    }
  }

  @media screen and (max-width: $smMax) {
    margin-bottom: 32px;

    svg {
      width: 264px;
      height: 154px;
    }
  }
}
