@use '@mfe/shared-css/global' as *;
.feedbackButton {
  height: 40px;
  position: fixed;
  top: 30%;

  /*
  * Can't just rotate(-90deg) as the right: 0px applies BEFORE
  * the transform is applied and our element won't be stuck fully to the right
  *
  * To fix this...
  * Move the element 50% of it's width into the right side of the page
  * Rotate around the center of the element point (which is now at the rightmost edge of the page) -90deg
  * Now move it 50% of it's height out of the right edge of the page
  * TADA!
  */
  right: 0px;
  transform: translate(50%, 0) rotate(-90deg) translate(0, -50%);
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid color(black);
  border-bottom: 0;

  background-color: color(white);
  padding: 0 12px;
  text-decoration: none;

  p {
    margin-left: 6px;
    position: relative;
    bottom: 1px;
  }

  p,
  svg {
    color: color(black);
  }

  &:hover {
    p,
    svg {
      color: color(gray_700);
    }
  }

  @include screenSize('medium') {
    position: static;
    padding: 0 20px;
    border-radius: 100px;
    background-color: color(gray_700);
    border: 0;
    transform: none;
    width: 142px;

    p,
    span,
    svg {
      color: color(gray_300);
    }

    &:hover {
      p,
      span,
      svg {
        color: color(gray_400);
      }
    }
  }
}
