.paymentRowContainer {
  display: flex;
  flex: 1;
  padding: 24px;
  padding-top: 0px;
}

.bodySubHeader {
  display: inline-flex;
  width: 100%;
  padding-bottom: 8px;
  align-items: center;
}

.logo > svg {
  height: 27px;
  margin-right: 8px;
}

.body {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
