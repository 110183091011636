@use '@mfe/shared-css/global' as *;

.container {
  background-color: color(white);
  border-radius: 16px;
  @include screenSize('small') {
    border-radius: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  height: 100%;

  @include screenSize('medium') {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @include screenSize('small') {
    padding: 16px;
  }
}

.twoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeButton {
  position: fixed;
  top: 24px;
  right: 24px;
  border-radius: 50%;
  &:hover {
    background-color: color(gray_300);
    outline: 2px solid color(gray_300);
  }
  @include screenSize('small') {
    top: 16px;
    right: 16px;
  }
}

.addressTitleContainer {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;

  @include screenSize('small') {
    font-size: 20px;
    line-height: 28px;
  }
}

.subtitleContainer {
  text-align: center;
  margin: 16px 0 24px;

  @include screenSize('medium') {
    margin-bottom: 28px;
  }

  @include screenSize('small') {
    margin-top: 8;
  }
}

.buttonsContainer {
  background-color: color(gray_100);
  border: 1px solid color(gray_200);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 24px 40px;

  @include screenSize('medium') {
    background-color: color(white);
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
  }
  @include screenSize('small') {
    border-radius: 0;
  }
}

.topTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  @include screenSize('medium') {
    align-items: flex-start;
  }
}

.errorContainer {
  display: flex;
  flex-direction: column;
  background-color: color(white);
  border-radius: 16px;
  align-items: center;
  padding: 80px 24px;
  @include screenSize('small') {
    border-radius: 0;
    padding: 40px 16px;
  }
}
