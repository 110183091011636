@use '@mfe/shared-css/global' as *;

.wrapper {
  @include query-above($mdMin) {
    padding-inline: 32px;
  }
}

.header {
  & > div {
    @include query-above($mdMin) {
      grid-template-columns: 200px 1fr 200px;
    }
  }
  padding: 24px;
  @include query-below($mdMin) {
    border-bottom: 8px solid color(gray_100);
  }
  @include query-below($xsMax) {
    padding: 16px;
  }
}

.page-content {
  display: flex;
  gap: 24px;
  max-width: 1248px;
  margin-inline: auto;

  @include query-above($mdMin) {
    margin-bottom: 72px;
  }
}

.left-card {
  flex: 2;
  background-color: color(white);
  @include query-above($mdMin) {
    border-radius: 16px;
  }
}

.title-and-selector {
  padding: 48px 48px 0px 48px;

  @include query-below($smMax) {
    padding: 24px 24px 0px 24px;
  }

  @include query-below('719px') {
    padding: 24px 16px 0px 16px;
  }
}

#aem-fragment-container {
  padding-inline: 24px;
  @include query-below($smMax) {
    padding: 0;
  }
}

.card-footer {
  div:first-child {
    padding: 16px 48px 48px 48px;

    @include query-below($smMax) {
      padding: 16px 24px 24px 24px;
    }

    @include query-below('719px') {
      padding: 16px 16px 24px 16px;
    }
  }
}

.submit-container {
  padding: 24px 48px;
  border-top: 1px solid color(gray_200);
  text-align: right;

  button {
    min-width: 223px;
  }

  @include query-below($smMax) {
    text-align: center;
    padding: 24px;

    button {
      width: 100%;
      max-width: 400px;
    }
  }

  @include query-below($smMin) {
    padding: 24px 16px;
  }
}

.title-subtitle {
  margin-bottom: 40px;
}

.grouped-selector {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.selector-options,
.offers-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.product-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid color(gray_200);
  border-radius: 8px;
  padding: 16px;
  gap: 24px;

  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.name-and-offer {
  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.select-button {
  width: 126px;
  @include screenSize('small') {
    width: 100%;
  }
}
