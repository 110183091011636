@use '@mfe/shared-css/global' as *;

.NavSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 24px;
}

.AverageUsageWrapper {
  padding-top: 12px;
  padding-bottom: 28px;
  text-align: right;
  flex-grow: 1;
}

@media screen and (min-width: $mdMin) {
  .NavSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 24px 0px 24px;
  }

  .AverageUsageWrapper {
    padding: 24px 0 24px 8px;
    margin-right: auto;
  }
}
