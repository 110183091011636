@use '@mfe/shared-css/global' as *;

.grid {
  max-width: 824px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 24px;
  @include screenSize('medium') {
    max-width: none;
  }

  @include screenSize('small') {
    gap: 8px;
  }
}

.accountInfoMargin {
  width: 100%;
  grid-column: 1;
  grid-row: 1;
}

.accountInfoCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  @include screenSize('small') {
    border-radius: 0;
  }
}

.cardTitle {
  border-bottom: 1px solid color(gray_200);
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include screenSize('extraSmall') {
    padding: 24px 16px;
  }
}

.accountInfoBody {
  display: flex;
  flex-direction: column;
  padding: 24px;

  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.accountInfoText {
  width: auto;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  &:not(:first-child) {
    padding-top: 24px;
    @include screenSize('extraSmall') {
      padding-top: 16px;
    }
  }
}

.addressCard {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  @include screenSize('small') {
    border-radius: 0;
  }
}

.addressBody {
  padding: 24px;
  white-space: pre-line;
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.contactBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 32px;
  @include screenSize('medium') {
    margin: 0;
  }
}

.emailMargin {
  width: 100%;
  margin-bottom: 24px;
  @include screenSize('small') {
    margin-bottom: 8px;
  }
}

.phoneMargin {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 24px;
    @include screenSize('small') {
      margin-bottom: 8px;
    }
  }
}

.billing {
  @extend .addressCard;
}

.edit {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
}

.loadingContainerWithPadding {
  @extend .loadingContainer;
  padding: 24px;
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.loading {
  @include shimmerAnimate;
  width: 100%;
  height: 24px;
  border-radius: 5px;
}

.loadingWithMargin {
  @include shimmerAnimate;
  width: auto;
  height: 24px;
  border-radius: 5px;
  margin: 24px;
}

.editableFooter {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 8px;
  @include screenSize('small') {
    flex-direction: column;
  }
}

.editableCardMargin {
  display: flex;
  flex-direction: column;
  padding: 24px;
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.editableEmailMargin,
.editableContactInfoWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.flagInput {
  display: flex;
  flex-direction: row;
  color: inherit;
  height: 56px;
  padding: 8px;
  align-items: center;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
}

.flagInputOpen {
  @extend .flagInput;
  border-radius: 8px 0 0 0;
  border-top: 1px solid color(teal_600);
  border-left: 1px solid color(teal_600);
  border-bottom: 1px solid color(teal_600);
  border-right: none;
  background-color: #f4feff;
}

.flagInputClosed {
  @extend .flagInput;
  border-radius: 8px 0 0 8px;
  border-top: 1px solid color(gray_300);
  border-left: 1px solid color(gray_300);
  border-bottom: 1px solid color(gray_300);
  border-right: none;
  background-color: color(gray_100);
}

.countryCodeInput {
  height: 56px;
  padding: 14px 8px 8px 0;
  align-items: center;
}

.countryCodeInputOpen {
  @extend .countryCodeInput;
  border-top: 1px solid color(teal_600);
  border-left: none;
  border-bottom: 1px solid color(teal_600);
  border-right: none;
  background-color: #f4feff;
}

.countryCodeInputClosed {
  @extend .countryCodeInput;
  border-top: 1px solid color(gray_300);
  border-left: none;
  border-bottom: 1px solid color(gray_300);
  border-right: none;
  background-color: color(gray_100);
}

.phoneInput {
  height: 56px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 8px;
  font-family: 'Helvetica';
  font-size: medium;
}

.phoneInputOpen {
  @extend .phoneInput;
  border-radius: 0 8px 0 0;
  border-top: 1px solid color(teal_600);
  border-right: 1px solid color(teal_600);
  border-bottom: 1px solid color(teal_600);
  border-left: none;
  background-color: #f4feff;
}

.phoneInputClosed {
  @extend .phoneInput;
  border-radius: 0 8px 8px 0;
  border-top: 1px solid color(gray_300);
  border-right: 1px solid color(gray_300);
  border-bottom: 1px solid color(gray_300);
  border-left: none;
  background-color: color(gray_100);
}

.dropdownItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  max-height: 120px;
  overflow-y: scroll;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 1;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
}

.dropdownContainerContent {
  display: flex;
  align-items: center;
}

.countryInputOpen {
  @extend .flagInput;
  border-radius: 8px 8px 0 0;
  border: 1px solid color(teal_600);
  background-color: #f4feff;
  justify-content: space-between;
}

.countryInputClosed {
  @extend .flagInput;
  border-radius: 8px;
  border: 1px solid color(gray_300);
  background-color: color(gray_100);
  justify-content: space-between;
}

.currentCountry {
  display: flex;
  align-items: center;
}

.currentCountryFlag {
  margin-top: 4px;
  margin-right: 8px;
}

.dropdownCountryContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sectionAlert {
  width: auto;
}

.editableContactMargin {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.phoneEditableContacts {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @include screenSize('small') {
    flex-direction: column;
  }
}

.phoneText {
  display: flex;
  flex-direction: row;
}

.accountHolderNameAndToolTip {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.billingAndShippingAddressContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include screenSize('small') {
    gap: 8px;
  }
}

.planInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text-spacing {
  display: flex;
  align-items: center;
}

.planHeader > * + *,
.attributesContainer > * + * {
  margin-top: 8px;
}

.broadbandFactsAccordion {
  border-top: 1px solid color(gray_200);

  &[open] > [class*='beam-accordion__content'] {
    max-height: fit-content;
  }
}
