@use '@mfe/shared-css/global' as *;

%skeleton {
  @include shimmerAnimate;
  border-radius: 4px;
}

.planName {
  @extend %skeleton;
  height: 24px;
  margin-bottom: 8px;
}

.price {
  @extend %skeleton;
  height: 40px;
}

.downloadSpeed {
  @extend %skeleton;
  height: 20px;
  margin-bottom: 8px;
}

.smallDetail {
  @extend %skeleton;
  height: 20px;
  margin-bottom: 8px;
}

.largeDetail {
  @extend %skeleton;
  height: 40px;
  margin-top: 24px;
}

.alertLoading {
  @extend %skeleton;
  height: 96px;
}

.buttonLoading {
  @extend %skeleton;
  height: 32px;
  width: 120px;
  border-radius: 16px;
}
