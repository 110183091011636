.internet-summary-container {
  hr {
    margin-top: 4px;
  }

  > * + * {
    margin-top: 4px;
  }
}

%internetSummaryRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.internetSummaryDataInfo {
  @extend %internetSummaryRow;
}

.pricing {
  @extend %internetSummaryRow;
  gap: 16px;
}

.cart-items-container {
  padding-bottom: 8px;
}

.contract {
  display: inline-block;

  > * {
    display: inherit;

    &:first-child {
      margin-right: 4px;
    }
  }

  [data-cy*='tooltip-text'] {
    vertical-align: bottom;
  }
}
