@use '@mfe/shared-css/global' as *;

.pageLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 0px 80px 0px;

  @include screenSize('medium') {
    padding: 0px 0px 32px 0px;
  }
  @include screenSize('small') {
    padding: 0;
  }
}

.pageHeader {
  width: 100%;
  margin: 64px auto;
  @include screenSize('small') {
    border-top: 1px solid color(gray_200);
    margin: 0px auto 8px;
  }
  @include screenSize('extraSmall') {
    margin: 0px auto;
    border-bottom: 1px solid color(gray_200);
  }
}

.diagnosticsList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.diagnosticsListItem {
  display: flex;
  border-bottom: 1px solid color(gray_200);
  gap: 8px;
  flex-direction: column;
  padding-bottom: 24px;
}

.resultsCard {
  display: flex;
  width: 100%;
  max-width: 824px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  border-radius: 16px;
  @include screenSize('small') {
    border-radius: 0;
    padding: 24px;
  }
  @include screenSize('extraSmall') {
    padding: 16px;
  }
  .header {
    display: flex;
    gap: 8px;
    padding-bottom: 40px;
    border-bottom: 1px solid color(gray_200);
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    @include screenSize('small') {
      flex-direction: column;
      align-items: flex-start;
    }
    .left {
      display: grid;
      gap: 8px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 48px;
    .subSection {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
}

.diagnosticResult {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
}

.diagnosticErrorBody {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cta {
  & > button {
    min-width: 180px;
  }

  @include screenSize('small') {
    width: 100%;
    & > button {
      width: 100%;
    }
  }
}
