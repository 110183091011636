@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  padding-bottom: 4px;
}

.card {
  display: flex;
  flex: 1;
  border: 1px solid color(gray_200);
  border-radius: 8px;
}
