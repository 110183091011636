@use '@vst/beam/sass/utils/colors';
@use 'sass:map';
@use '../_index.scss' as *;
@use '@mfe/shared-css/global' as *;

.wrapperToast {
  margin: 0 auto 32px auto;

  @media screen and (max-width: $lgMin) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: $mdMin) {
    background-color: map-get($map: colors.$raw-colors, $key: 'white');
    padding: 24px 24px 0;
    margin-bottom: 0;
  }

  @media screen and (max-width: $xsMax) {
    padding: 24px 16px 0;
  }
}

.toastContainer {
  display: flex;
  max-width: 1248px;
  border-radius: 16px;
  border: 1px solid map.get($additional-colors, 'alert_300');
  border-left-width: 16px;
  background-color: map-get($map: colors.$raw-colors, $key: 'white');
  animation: toast-in-top 0.8s;
  padding: 24px;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $mdMin) {
    max-width: 100%;
    animation: toast-in-top 2s;
    padding: 24px 16px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.title {
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 16px;
  p {
    color: map-get($map: colors.$raw-colors, $key: 'gray_600');
  }
}

.actionLink {
  a:hover {
    text-decoration: underline;
  }
}

.closeButtonContainer {
  margin-left: auto;
}

.linkText {
  color: map-get($map: colors.$raw-colors, $key: 'gray_800');
}

.titleText {
  color: map-get($map: colors.$raw-colors, $key: 'gray_800');
}

@keyframes toast-in-top {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0%);
  }
}
