@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 320px;
  @media screen and (min-width: $mdMin) {
    padding: 24px 32px;
  }
  @media screen and (max-width: $mdMin) {
    padding: 0;
  }
}
