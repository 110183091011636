@use '@mfe/shared-css/global' as *;

.cancel-subscription-modal {
  :global {
    .beam-btn--primary {
      transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;

      &:hover {
        transform: scale(1.03);
        opacity: 0.9;
      }
    }
  }
}

.modal-body {
  text-align: center;
}

.remove-add-on-modal {
  [class*='beam-btn--primary'] {
    min-width: fit-content;
  }
  [class*='beam-modal__body'] {
    padding-bottom: 0px;
    min-height: fit-content;
  }
  [class*='beam-modal__content'] p {
    margin-bottom: 8px;
  }
}

.subtitle-addon-name {
  white-space: nowrap;
  font-weight: bold;
}

.cross-out-item {
  text-decoration: line-through;
}
