@use '@mfe/shared-css/global' as *;
:where(.title, .benefits-list) {
  padding: 24px;

  @include query-below(calc(#{$xsMax} - 1px)) {
    padding: 24px 16px;
  }
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  max-width: 40px;
  border-radius: 100%;
}

.title {
  border-bottom: 1px solid color(gray_200);
}

.benefits-list > *:last-child {
  margin-top: 24px;
}

.benefit {
  display: flex;
  flex-direction: row;
}

.benefit-text {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 16px;
}
