@use '@mfe/shared-css/global' as *;

$containerWidth: 244px;
$containerMargin: 16px;

.container {
  width: $containerWidth;
  position: absolute;
  top: 72px;
  right: 32px;
  background-color: white;
  border: 1px solid color(gray_300);
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
  margin-top: 8px;
  z-index: 1000;

  .greeting {
    padding: $containerMargin $containerMargin 8px $containerMargin;
  }

  .accountType {
    display: flex;
    padding: 0px $containerMargin;
    margin-bottom: $containerMargin;
  }

  .actions {
    padding-left: 8px;
  }

  .actionContainer {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    color: color(gray_600);
    &:hover {
      background-color: color(gray_200);
      color: color(gray_600);
      cursor: pointer;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.profileInfoHeader {
  border-bottom: 1px solid color(gray_200);
}

.actionMargin {
  margin: 8px 0;
}
