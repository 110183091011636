@use '@mfe/shared-css/global' as *;

.current-plan-collapsible-details {
  position: fixed;
  top: var(--navigation-header-height, 0);
  background-color: white;

  z-index: 1;

  &[open] .collapsible-content[data-scrollable='yes'] {
    max-height: calc(
      100vh -
        (
          var(--navigation-header-height, 0px) +
            var(--collapsible-summary-height, 0px)
        )
    );
    overflow-y: auto;
  }
}

.current-plan-collapsible-summary {
  background-color: color(white);
  border-top: 1px solid color(gray_200);
  border-bottom: 1px solid color(gray_200);
}

.current-plan-summary-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapsible-content {
  & > * {
    padding-inline: 32px;

    @include query-below($smMax) {
      padding-inline: 24px;
    }
  }
}

.teal-color {
  div[data-cy='attribute-titleAddOns'] {
    color: color(teal_600);
  }
}

.hidden {
  display: none;
}
