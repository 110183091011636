@use '@mfe/shared-css/global' as *;

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  [class*='beam-empty-state__outer'] {
    display: flex;
    align-items: center;
    svg {
      color: color(black);
    }
    @include screenSize('small') {
      padding: 0 24px;
      height: 424px;
    }
  }
}
