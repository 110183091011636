@use '@mfe/shared-css/global' as *;

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 824px;

  @media screen and (max-width: 320px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (min-width: 321px) and (max-width: $mdMin) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.titleContainer {
  text-align: center;
  margin-bottom: 12px;
  line-height: 32px;
  color: color(gray_800);
}

.textContainer {
  text-align: center;
  margin-bottom: 48px;
  white-space: pre-line;
  color: color(gray_600);
  @media screen and (max-width: $mdMin) {
    margin-bottom: 32px;

    p {
      font-size: 16px;
    }
  }

  @media screen and (min-width: $mdMin) {
    p {
      font-size: 18px;
    }
  }
}

.title {
  @media screen and (max-width: $mdMin) {
    font-size: 20px;
  }

  @media screen and (min-width: $mdMin) {
    font-size: 24px;
  }
}

.titleContainer {
  margin-bottom: 12px;
}
