@use '@mfe/shared-css/global' as *;

.textContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  @include screenSize('small') {
    padding: 16px;
  }
}

.closeButton {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &:hover {
    background-color: color(gray_300);
    outline: 2px solid color(gray_300);
  }
  @include screenSize('small') {
    top: 16px;
    right: 16px;
  }
}
