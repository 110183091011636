@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 80px;
  padding: 0 32px;

  @include screenSize('medium') {
    padding: 24px 24px 0 24px;
    margin: unset;
  }

  @include screenSize('small') {
    padding: 0;
    overflow-x: hidden;
    align-items: flex-start;
  }
}

.bodyGrid {
  display: grid;
  grid-template-areas: 'usageSummary cardsList' 'commonQuestions cardsList' '. cardsList';
  width: 100%;
  max-width: 1248px;
  grid-gap: 24px;
  @include screenSize('medium') {
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-row-gap: 24px;
  }

  @include screenSize('small') {
    grid-gap: 8px;
  }
}

// body margins
.balanceSummaryMargin {
  grid-area: balanceSummary;
  grid-column: 1;
  grid-row: 1;
  @include screenSize('small') {
    width: 100vw;
  }
}

.commonQuestionsMargin {
  grid-area: commonQuestions;
  width: 100%;
  max-width: 400px;
  grid-column: 1;
  grid-row: 2;
  @include screenSize('medium') {
    max-width: none;
    grid-row: -2;
  }
  @include screenSize('small') {
    padding: 0;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }
}

.rightSide {
  grid-area: cardsList;
  grid-column: 2;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 24px;
  width: 100%;
  @include screenSize('large') {
    grid-template-columns: 1fr;
  }
  @include screenSize('medium') {
    grid-column: 1;
    grid-row: 2;
  }
  @include screenSize('small') {
    row-gap: 8px;
  }
}

// right margins
.invoiceMargin {
  width: 100%;
  grid-column: 1/3;
  grid-row: 1;

  @include screenSize('medium') {
    grid-column: 1/3;
    grid-row: 1;
  }
}

.articleMargin {
  width: 100%;
  grid-column: 1/3;
  grid-row: 2;
  @include screenSize('large') {
    grid-column: 1/3;
    grid-row: 2;
  }
}

// Common questions refactored
.commonQuestionsWrapper {
  background-color: transparent;
  padding: 0px 24px;

  @include screenSize('medium') {
    padding: 0px 24px 24px 24px;
  }
}

.accordionDescription {
  * + * {
    margin-top: 16px;
  }

  a + a {
    margin-top: 0px;
  }
}
