.boletoContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 500px;
  width: 100%;
}

.boletoLabel {
  padding-bottom: 8px;
}

.boleto {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;

  background: color(gray_200);
  border-radius: 8px;
}

.barCode {
  flex: 1;
  padding: 0px 16px;
}

.icon {
  padding: 16px;
  border: none;
  border-left: 2px solid color(gray_300);
  background: color(gray_300);
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: flex;

  [class='beam-spinner__circle'] {
    stroke: color(gray_500);
  }
}
