@use '@mfe/shared-css/global' as *;
$menuZIndex: 1000;
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $menuZIndex - 1;
}

.statementMenuContainer {
  position: relative;
}

.statementMenuButton {
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.25s;

  svg {
    color: color(gray_800);
  }

  &:hover {
    background-color: color(gray_100);
  }

  &.disabled {
    cursor: default;
    svg {
      color: color(gray_400);
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.statementDropdown {
  display: flex;
  flex-direction: column;
  min-width: 200px;

  position: absolute;
  top: 30px;
  right: 0;
  z-index: $menuZIndex;

  background-color: color(white);
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.dropdownItem {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: color(gray_100);
  }
}
