@use '@mfe/shared-css/global' as *;
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.listItem {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border: none;
  background-color: transparent;
  width: 100%;
  align-items: center;
}
.mobileLastListItem {
  border-bottom: none;
}
.button {
  background-color: transparent;
  border: 1px solid color(gray_800);
  padding: 11px 28px 13px;
  border-radius: 100px;
  width: 100%;
  font-size: 16;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  font-size: 16px;
}

.ease {
  transition: transform 0.25s ease 0s;
}

.animateHeight {
  transition: max-height 0.25s ease-in-out 0s;
}

.rotate180 {
  transform: rotate(180deg);
}

.transformHeight {
  transition: height 0.25s ease-in-out 0s;
}

.listItemPayment {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0px 0px;
  border: none;
  background-color: transparent;
  width: 100%;
  align-items: center;
}
