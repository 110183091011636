@use '@mfe/shared-css/global' as *;

.container {
  display: block;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  @include screenSize('medium') {
    max-width: none;
  }
  @include screenSize('small') {
    border-radius: 0;
  }
}

%topper {
  content: '';
  height: 8px;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

%card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.card {
  @extend %card;
  &:before {
    @extend %topper;
    background: linear-gradient(90deg, color(blue_700), color(teal_500));
  }
}

.cardWarning {
  @extend %card;
  &:before {
    @extend %topper;
    background: color(warning_500);
  }
}

.link {
  display: flex;
  padding: 24px;
  border-top: 1px solid color(gray_200);
  align-items: center;
  color: color(gray_600);
  @include screenSize('small') {
    padding: 20px 24px;
  }
}

.errorCard {
  padding: 48px;
  border-radius: 16px;

  @include screenSize('small') {
    border-radius: 0px;
    padding: 24px;
    max-width: none;
    min-width: none;
  }

  [class*='beam-empty-state__outer'] {
    display: flex;
    align-items: center;
    height: 424px;
    min-width: 320px;
    max-width: 400px;
    @include screenSize('medium') {
      border-radius: 0px;
      max-width: none;
      min-width: none;
    }

    svg {
      color: color(black);
    }
  }
}
