@use '@mfe/shared-css/global' as *;

.layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex: 1;
  width: 100%;
  align-self: center;
  box-sizing: border-box;
}
