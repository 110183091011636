@use '@mfe/shared-css/global' as *;
.container {
  background-color: color(blue_800);
  padding: 16px 0px;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 28px;
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;

  @media only screen and (max-width: 1339px) {
    margin-right: 32px;
    margin-left: 32px;
  }

  @include screenSize('medium') {
    margin-right: 24px;
    margin-left: 24px;
  }
}

.message,
a.message {
  color: white;
  &:hover {
    color: white;
  }
}

.close {
  background-color: transparent;
  border: none;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
}
