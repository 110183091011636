@use '@mfe/shared-css/global' as *;

.cart {
  flex: 1;
}

.vertical-spacing > * + * {
  margin-top: 24px;
}

.content > * + * {
  margin-top: 16px;
}

.subscription-price {
  padding-bottom: 16px;
  border-bottom: 1px solid color(gray_200);

  .promotions,
  .promotions > * + * {
    margin-top: 8px;
  }

  .promotion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .promotion-name {
    * + * {
      margin-left: 4px;
    }

    svg {
      margin-bottom: 2px;
    }
  }

  .promotion-price {
    @include query-below(450px) {
      min-width: 96px; // to fit 3 digit price with 2 decimals
      text-align: right;
    }
  }
}

.total {
  display: flex;
  justify-content: space-between;
}

.addon {
  display: flex;
  align-items: center;
  gap: 16px;

  .icon {
    flex-basis: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
    border-radius: 4px;
    padding: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.collapsibleSummaryBackground {
  margin: 0;
  padding: 0;
  background-color: color(white);
  border-bottom: 1px solid color(gray_200);

  [data-cy='shopping-cart-icon'] {
    margin-right: 4px;
  }

  [data-cy='chevron'] {
    margin-right: 24px;

    @include query-below($xsMax) {
      margin-right: 16px;
    }
  }
}

.collapsibleSummaryContent {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  @include query-below($xsMax) {
    padding: 16px;
  }
}

.collapsibleCartDetails {
  margin: 0;
  background-color: color(white);
  padding: 24px;
  border-bottom: 1px solid color(gray_200);

  button {
    max-width: 336px;
    display: block;
    margin-inline: auto;
  }

  @include query-below($xsMax) {
    padding: 16px;
  }
}

.empty-cart-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid color(gray_200);
}

.empty-cart-footer {
  margin-top: 24px;

  button {
    margin-block: 24px;
  }
}
