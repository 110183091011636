@use '@mfe/shared-css/global' as *;

.addOnLoading {
  width: 100%;
}

.titleLoading {
  @include shimmerAnimateLightBlue;
  width: 175px;
  height: 32px;
  border-radius: 5px;
  margin-right: auto;
}

.captionsLoading {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}

.captionLoading {
  @include shimmerAnimateLightBlue;
  height: 18px;
  border-radius: 5px;
  width: 100%;
  margin: 3px 0;
}

.linkLoading {
  @include shimmerAnimateLightBlue;
  width: 104px;
  height: 38px;
  border-radius: 5px;
}
