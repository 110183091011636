@use '@mfe/shared-css/global' as *;

.loadingContainer {
  @include query-below($smMax) {
    background-color: color(white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
}

.content {
  min-height: 356px;
  display: flex;
  align-items: center;

  @include query-below($smMax) {
    margin: 0;
    padding: 16px;
  }
}
