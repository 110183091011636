@use '@mfe/shared-css/global' as *;

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: $smMax) {
    background-color: color(white);
  }

  @media screen and (min-width: $mdMin) {
    padding: 72px 32px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1200px;

  @media screen and (min-width: $mdMin) {
    gap: 24px;
  }
}

.payments {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  background-color: color(white);

  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
  }
}

.paymentsGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: left;
}

.paymentsContainer {
  display: flex;
  flex-direction: column;
}

.unavailable {
  height: 141px;
  padding: 24px;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  align-self: center;
  display: flex;
  justify-content: center;
}

.paymentsHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
}

.backButton {
  display: flex;
  align-items: center;
  align-self: flex-start;

  // remove button styling
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  > svg {
    height: 24px;
    width: 24px;
  }

  @media screen and (max-width: $smMax) {
    padding-inline-start: 18px;
  }
}

.gridHeader {
  text-align: center;
  background-color: color(gray_200);
  padding: 16px 25px;
}

// loading styles
.paymentItem {
  @include shimmerAnimate;
  height: 24px;
  border-radius: 5px;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: color(gray_200);
}

.paymentItem_Long {
  @extend .paymentItem;
  width: 96px;
}

.paymentItem_Short {
  @extend .paymentItem;
  width: 50px;
}

.paymentsHistoryErrorWrapper {
  @media only screen and (max-width: $smMax) {
    padding: 16px;
  }
}

.paymentsHistoryErrorContainer {
  * + * {
    margin-top: 4px;
  }
}
