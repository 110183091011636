@use '@mfe/shared-css/global' as *;

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.description {
  max-width: 358px;
}

.backToMyViasat {
  max-width: 223px;
  margin-top: 40px;

  @include query-below($smMax) {
    max-width: 400px;
  }
}
