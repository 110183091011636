@use '@mfe/shared-css/global' as *; // page

// display alert in full width ECJ-2536
.notificationsContainer {
  width: calc(100vw - 64px);
  max-width: 1248px;
  min-width: 320px;
  padding-bottom: 32px;

  :global(.beam-page-alert__description) {
    display: block;
  }

  :global(.beam-page-alert__description):has(
      + :global(.beam-page-alert__actions):empty
    ) {
    margin-bottom: 0;
  }

  // Adds space between notifications
  > :not(:last-child) {
    margin-bottom: 24px;
  }

  &:empty {
    display: none;
  }

  @include screenSize('small') {
    width: calc(100% - 24px - 24px);
    padding: 24px 24px 32px;
  }
  //fixing 320 view not scaling the width properly
  @include screenSize('extraSmall') {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 32px;
  }
}

.notification {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: color(white);
  box-sizing: border-box;

  // pageAlert content
  > div {
    display: flex;
    flex-direction: column;
  }
}

.flexColumn {
  flex-direction: column;
}

.gridItem {
  text-align: center;
  padding: 16px 24px;
  display: flex;
  justify-content: center;

  &:not(:nth-last-child(-n + 5)) {
    border-bottom: 1px solid color(gray_200);
  }

  > svg {
    height: 24px;
    margin-right: 8px;
  }
}
