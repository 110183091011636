@use '@mfe/shared-css/global' as *;

.buyMoreHistoryLoading {
  @include shimmerAnimate;
  border-radius: 4px;
  height: 40px;
}

.productList {
  margin-top: 24px;
  > div {
    padding: 8px 0;
  }

  > * + * {
    border-top: 1px solid color(gray_200);
  }
}

.listRow {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-column-gap: 12px;
}

.accordionDetails {
  border-top: 1px solid color(gray_200);
  padding: 24px 0 0;

  summary svg {
    transition: transform 0.25s ease 0s;
  }

  &[open] {
    summary svg {
      transition: transform 0.25s ease 0s;
      transform: rotate(180deg);
    }
  }
}

.accordionSummary {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
}

.labelsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span {
    color: color(gray_600);
  }
}

.usageBarContainer {
  margin-top: 24px;
}
