@use '@mfe/shared-css/global' as *;

.collapsible {
  width: 100%;

  &[open] {
    box-shadow: var(--has-box-shadow, 'none');

    svg[data-cy='chevron'] {
      transform: rotate(180deg);
    }
  }
}

.collapsible-summary {
  padding: 16px 32px 16px 24px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  &::marker {
    display: none;
  }
  // Hide details marker in Safari
  &::-webkit-details-marker {
    display: none;
  }

  @include query-below($smMax) {
    padding-inline: 24px;
  }

  @include query-below($xsMax) {
    padding-inline: 16px;
  }
}

.collapsible-summary-section {
  display: flex;
  align-items: center;
  gap: 8px;
}
