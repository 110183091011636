@use '@mfe/shared-css/global' as *;

.body {
  display: flex;
  flex-direction: column;
  padding: 48px;
  gap: 40px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @include query-above($lgMax) {
    max-width: 824px;
  }

  @include query-below($smMax) {
    width: 100%;
    height: 100%;
  }
}

.text-icon {
  margin-top: 24px;
}

.text-icon {
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer {
  display: flex;
  border-top: 1px solid color(gray_200);
  justify-content: space-between;
  padding: 24px 48px;
  align-items: center;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;

  @include query-above($lgMax) {
    max-width: 824px;
  }

  @include query-below($mdMax) {
    flex-direction: column-reverse;
    gap: 24px;
    button {
      max-width: 400px;
    }
  }
}

.hr {
  width: 100%;
  border: none;
  border-top: 1px solid color(gray_200);
}
