@use '@mfe/shared-css/global' as *;

.balanceCard {
  background: linear-gradient(
    62.02deg,
    color(blue_700) 17.68%,
    color(teal_600) 151.32%
  );
  box-sizing: border-box;
  width: 400px;
  max-width: 400px;
  padding: 24px;
  border-radius: 16px;

  @include screenSize('medium') {
    width: 100%;
    max-width: 100%;
  }
  @include screenSize('small') {
    border-radius: 0px;
  }
  @include screenSize('extraSmall') {
    padding: 16px;
  }
}

.titleAndBadge {
  display: flex;
  justify-content: space-between;
}

.loadingTitle,
.loadingBadge,
.loadingBalance,
.loadingPaymentDue,
.loadingAutoPay,
.loadingSeparator,
.loadingFooter {
  @include shimmerAnimateBlue;
  border-radius: 4px;
}

.loadingTitle {
  width: 64px;
  height: 24px;
  margin-bottom: 4px;
}
.loadingBadge {
  width: 114px;
  height: 24px;
}

.loadingBalance {
  width: 143px;
  height: 48px;
  margin-bottom: 24px;
}

.loadingPaymentDue {
  width: 100%;
  height: 20px;
  margin-bottom: 24px;
}

.loadingAutoPay {
  width: 100%;
  height: 56px;
  margin-bottom: 24px;
}

.loadingSeparator {
  height: 1px;
  width: 100%;
}

.loadingFooter {
  width: 100%;
  height: 40px;
  margin-top: 24px;
}
