@use '@mfe/shared-css/global' as *;

.wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}

.paymentContainer {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 320px;

  @media screen and (min-width: $mdMin) {
    margin: 32px;
    border-radius: 16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  background-color: color(white);

  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
    max-width: 576px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 32px 0px 0px;
}

%sectionContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 48px;

  @media screen and (max-width: $smMax) {
    padding: 0px 24px;
  }

  @media screen and (max-width: $xsMax) {
    padding: 0px 16px;
  }
}

.titleContainer {
  @extend %sectionContainer;
  padding-bottom: 24px;
}

.amountContainer {
  @extend %sectionContainer;
  padding-bottom: 24px;
}

.methodContainer {
  @extend %sectionContainer;
  padding-bottom: 12px;

  > svg {
    height: 30px;
  }
}

.checkboxContainer {
  @extend %sectionContainer;
  padding-bottom: 20px;
}

.footerContainer {
  @extend %sectionContainer;
  padding: 24px;
  flex: 1;

  @media screen and (max-width: $xsMax) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

// payment amount / method
.radioGroupContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    height: 30px;
    margin-right: 16px;
  }
}

.radioButton {
  display: flex;
  flex: 1;
  padding: 20px 16px;

  > label {
    @extend .label;
  }
}

.textInput {
  @extend .radioButton;
  flex-direction: column;
  padding-top: 0px;
  margin-top: -8px;

  // TextField
  > div {
    max-width: 200px;

    // beam label not used
    > label {
      display: none;
    }

    input[name] {
      // This important is required
      // because @vst/beam is setting
      // inline styles on the <input/>
      padding-left: 26px !important;
    }

    // helper footer
    > div:last-child {
      margin: 12px 0px 0px;
    }
  }
}

.differentMethodContainer {
  @extend %sectionContainer;
  padding-left: 0px;
  padding-right: 0px;
}

.differentMethodContent {
  display: flex;
  flex: 1;
  flex-direction: column;

  @include query-below($smMax) {
    padding-bottom: 32px;
  }
}

.vppFrameLoading {
  height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// payment buttons
.paymentButtonsContainer {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  @media screen and (max-width: $smMax) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.paymentButton,
.cancelButton {
  @media screen and (max-width: $smMax) {
    max-width: 414px;
    width: 100%;
  }
}

.paymentButton {
  @media screen and (min-width: $mdMin) {
    min-width: 223px;
  }
  & > [class*='beam-spinner-wrap'] {
    margin: 0 16px 0 0;
  }
}

// payment status
.statusAlert {
  @extend %sectionContainer;
  background-color: color(warning_100);
  margin: 0px 48px 24px;
  padding: 12px 16px;
  border-radius: 8px;
}

.statusContainer {
  @extend %sectionContainer;
  padding-bottom: 24px;
}

.statusMethodContainer {
  @extend .statusContainer;
  padding-bottom: 32px;
}

.terms {
  background-color: color(gray_100);
  padding: 12px 16px;
  border-radius: 8px;
}

.statusFooterContainer {
  @extend .footerContainer;
  align-items: flex-end;

  @media screen and (max-width: $smMax) {
    align-items: center;
  }
}
