@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@mfe/shared-css/global' as *;
@use '../_index.scss' as *;

.inputContainer {
  max-width: 376px;
  height: 56px;
  padding: 8px 0 0 16px;
  position: relative;

  @media screen and (max-width: 766px) {
    max-width: 100%;
  }
}

.inputErrorContainer {
  background-color: map.get($additional-colors, 'warning_50');
  border: 1px solid map.get(colors.$raw-colors, 'alert_400');
}

.inputDefaultContainer {
  background-color: map.get($additional-colors, 'gray_50');
  border: 1px solid map.get(colors.$raw-colors, 'teal_600');
}

.inputAndPrefixContainer {
  max-height: 30px;
  display: flex;
  align-items: center;
  padding-top: 2px;
  width: 100%;
}

.phoneInputOpen {
  background-color: transparent;
  border: none;
  color: map.get(colors.$raw-colors, 'gray_800');
  outline: none;
  font: 400 18px 'Source Sans Pro';
  line-height: 24px;
  padding-bottom: 11px;
  width: 100%;
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(colors.get-var-name('text-input-text'));
  }

  &:-webkit-autofill {
    animation: onAutoFillStart 20s ease-in-out infinite;
  }
}

.phoneInputClosed {
  border-radius: 0 8px 8px 0;
  border-left: none;
}

.countryInputOpen {
  @extend .inputContainer;
  border-radius: 8px 8px 0 0;
}

.countryInputClosed {
  @extend .inputContainer;
  border-radius: 8px;
}

.font {
  color: map.get(colors.$raw-colors, 'gray_600');
}

.editableFooter {
  display: flex;
  flex-direction: row-reverse;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }

  @media screen and (min-width: $mdMin) and (max-width: $mdMax) {
    margin-top: 0;
    order: 0;
  }

  button {
    @media screen and (max-width: $mdMin) {
      width: 100%;
    }
    margin-top: 16px;
  }
}

.requiredField {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.phoneNumberError {
  margin-left: 4px;
  color: map.get(colors.$raw-colors, 'alert_400');
}

.helperText {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-top: 8px;
  max-width: 376px;
  @include screenSize('small') {
    max-width: none;
  }
}

.phoneInput {
  input {
    border-radius: 8px;
  }
}
