@use '@mfe/shared-css/global' as *;

.content-wrapper {
  width: 586px;
  margin: 40px auto;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  @include query-below($smMax) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &:before {
    content: '';
    height: 8px;
    background: linear-gradient(90deg, color(blue_700), color(teal_500));
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}
