@use '@vst/beam/sass/utils/colors';
@use 'sass:map';

$additional-colors: (
  'darkblue_100': #49667b,
  'darkblue_200': #394a57,
);

// This is actually used for a loader that was built for the old banner
// Not sure if the loader is ever displayed anymore, will need investigating
@mixin shimmerAnimateCustom {
  background: #494a57;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    map.get($additional-colors, 'darkblue_200') 4%,
    map.get($additional-colors, 'darkblue_100') 25%,
    map.get($additional-colors, 'darkblue_200') 36%
  );
  background-size: 1000px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
