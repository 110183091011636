@use '@mfe/shared-css/global' as *;

.monthly:hover,
.daily:hover {
  cursor: pointer;
}

.usage-toggle-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.usage-toggle {
  display: flex;
  flex-direction: row;
  width: 278px;
  height: 56px;
  border-radius: 100px;
}

.toggleButton {
  padding: 8px 16px;
  width: 136px;
  height: 40px;
  background: color(gray_100);
  border-radius: 1000px;
  border: none;
  cursor: pointer;
}

.toggleButtonClicked {
  padding: 8px 16px;
  width: 136px;
  height: 40px;
  color: color(gray_100);
  background: color(gray_700);
  border: none;
  border-radius: 1000px;
  cursor: pointer;
}

@media screen and (min-width: $smMin) {
  .usage-toggle {
    width: 348px;
    height: 56px;
  }
  .toggleButton,
  .toggleButtonClicked {
    width: 175px;
    height: 40px;
  }
}

@media screen and (min-width: $mdMin) {
  .usage-toggle {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 48px;
  }

  .toggleButton,
  .toggleButtonClicked {
    width: 192px;
    height: 32px;
  }
}
