@use '@mfe/shared-css/global' as *;

.notificationCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  @include screenSize('extraSmall') {
    border-radius: 0;
  }
}
