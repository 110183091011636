.unleashedBarChart {
  margin-top: 40px;
}

.unleashedTotalDataUsed {
  display: flex;
  justify-content: center;
}

.nonUnleashedWrapper {
  display: flex;
  flex-direction: column;
}

.nonUnleashedBadge {
  margin: 0 auto;
}
