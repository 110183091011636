@use '@mfe/shared-css/global' as *;

.containerWhite {
  position: relative;
  padding: 32px 24px 24px 24px;
  overflow: hidden;

  background-color: color(white);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  > * + * {
    margin-top: 24px;
  }

  @include screenSize('medium') {
    max-width: none;
  }

  @include screenSize('small') {
    border-radius: 0;
  }

  @include screenSize('extraSmall') {
    min-width: fit-content;
    padding: 32px 16px 24px 16px;
  }

  [class*='beam-empty-state__outer'] {
    display: flex;
    align-items: center;
    min-width: 320px;
    padding: 72px 0;

    h4 {
      margin: 0;
    }
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    display: inline-block;
    width: 100%;
    height: 8px;

    background: linear-gradient(90deg, color(blue_700), color(teal_500));
  }

  [role='alert'] {
    padding: 0;
  }
}

.containerEsvt {
  [class*='unleashedUsageSummaryTitle'] {
    display: none;
  }
  [class*='unleashedUsageSummaryContent'] {
    margin-top: 0;
  }
}

.bar {
  height: 16px;
  align-self: stretch;
  margin: 4px 0;

  border-radius: 37px;
  background: linear-gradient(
    90deg,
    #00c208 0%,
    #c6f23a 45.5%,
    #fbff48 52%,
    #fcff48 86%,
    #fe7621 89.5%,
    #f00 100%
  );
}

.scoreContent {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.typicalScoreContent {
  width: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: calc(55% - 47.5px);
  span {
    width: 75px;
  }
}

.typicalScoreBox {
  position: relative;
}

.leastData {
  position: absolute;
  left: 0;
}

.mostData {
  position: absolute;
  right: 0;
}

.loadingTitile {
  @include shimmerAnimate;
  border-radius: 4px;
  width: 108px;
  height: 20px;
}

.loadingThermometer {
  @include shimmerAnimate;
  height: 16px;
  width: 100%;
  border-radius: 36px;
}

.loadingAlert {
  @include shimmerAnimate;
  width: 352px;
  height: 152px;
  border-radius: 4px;
  @include screenSize('medium') {
    width: 100%;
  }
}
