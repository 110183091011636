@use '@mfe/shared-css/global' as *;

.container {
  display: inline-flex;
  position: relative;
  margin-left: 16px;
  align-items: center;
}

.toggleContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 40px;
  padding: 8px;
  gap: 4px;

  &:hover {
    cursor: pointer;
    background-color: color(gray_100);
    border-radius: 8px;
    color: color(gray_600);
  }

  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .languageText {
    @include screenSize('extraSmall') {
      display: none;
    }
  }
}

.chevron {
  transition: transform 0.2s;

  &.flip {
    transform: rotate(180deg);
  }
}

$dropdownZIndex: 1000;

.dropdownContainer {
  position: absolute;
  top: 64px;
  right: 0;
  cursor: pointer;
  background-color: color(white);
  border-radius: 8px;
  border: 1px solid color(gray_300);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  overflow: hidden;
  z-index: $dropdownZIndex;

  p,
  span {
    display: block;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $dropdownZIndex - 1;
}

.item,
.selectedItem {
  display: flex;
  flex-direction: row;
  min-width: 200px;
  padding: 8px 16px;

  &:hover {
    background-color: color(gray_200);
  }
}

.selectedItem {
  justify-content: space-between;
}
