@use '@mfe/shared-css/global' as *;

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @include screenSize('small') {
    padding: 0px 16px 24px;
  }
}

.steps-background {
  height: 4px;
  border-radius: 4px;
  background-color: color(gray_200);
  width: 100%;
  max-width: 680px;
  overflow: hidden;
}

.steps-bar {
  height: 4px;
  background-color: color(teal_600);
}
