@use '@mfe/shared-css/global' as *; // index

.container {
  width: 100%;
  display: flex;
  padding: 24px;
  gap: 24px;
  flex-direction: column;
  border-radius: 16px;

  @include screenSize('medium') {
    width: 100%;
  }
  @include screenSize('small') {
    border-radius: 0;
    padding: 24px 16px;
  }
}

.learnMoreButton {
  align-self: center;
  width: 352px;
  max-width: 400px;
  @include screenSize('small') {
    width: 100%;
  }
}
