@use '@mfe/shared-css/global' as *;

.addressForm {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: color(white);
  border-bottom: 1px solid color(gray_200);
  @media screen and (max-width: $xsMax) {
    padding: 24px 16px 24px 16px;
  }
}

.addressFormWithoutSms {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: color(white);
  border-radius: 0 0 16px 16px;
  @media screen and (max-width: $smMax) {
    border-radius: 0;
  }
  @media screen and (max-width: $xsMax) {
    padding: 0 16px 24px 16px;
  }
}

.inputContainer {
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}

.zipCodeContainer {
  width: 100%;
  margin-right: 16px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;

  @media screen and (max-width: $smMax) {
    margin-top: 16px;
  }

  @media screen and (max-width: 575px) {
    margin-top: 24px;
  }

  .wrapper {
    @media screen and (max-width: $smMax) {
      width: 100%;
    }
  }

  a {
    margin-right: 20px;
    color: color(gray_800);
    text-decoration: none;
    font-weight: 600;
  }

  @media screen and (max-width: $smMax) {
    flex-direction: column;

    a {
      margin-right: 0;
      margin-top: 20px;
      order: 2;
    }
  }
}
