/*
 * NOTE: YOU WILL NEED TO RUN `npm install` when you make changes to files in this folder
 * in order for the @mfe/shared-css alias to work!
 */
@use 'sass:map';
@use '@vst/beam/sass/utils/colors' as beam-colors;

@forward './screenSize.scss';
@forward './queries';
@forward './breakpoints';

// Animations
@mixin shimmerAnimate {
  background: #777;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    color(gray_200) 4%,
    color(gray_300) 25%,
    color(gray_200) 36%
  );
  background-size: 1000px 100%;
}

@mixin shimmerAnimateBlue {
  background: color(blue_700);
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    color(blue_600) 4%,
    color(blue_500) 25%,
    color(blue_600) 36%
  );
  background-size: 1000px 100%;
}

@mixin applyTopGradientBorder {
  // Don't forget to set position: relative on the parent element
  &:before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    display: inline-block;
    width: 100%;
    height: 8px;

    background: linear-gradient(90deg, color(blue_700), color(teal_500));
  }
}

@mixin shimmerAnimateLightBlue {
  background: color(blue_300);
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    color(blue_200) 4%,
    color(blue_100) 25%,
    color(blue_200) 36%
  );
  background-size: 1000px 100%;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@function color($color) {
  @if map-has-key(beam-colors.$raw-colors, #{$color}) {
    @return map.get(beam-colors.$raw-colors, #{$color});
  } @else {
    @error "Color '#{$color}' does not exist";
  }
}

$mobileTabBarHeight: 60px;
