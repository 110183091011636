@use '@mfe/shared-css/global' as *; // index
.invoiceFrame {
  width: 100%;
  border-radius: 16px;

  @include screenSize('small') {
    border-radius: 0;
    width: 100vw;
    overflow-x: auto;
    overscroll-behavior: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  overflow: hidden;
  background-color: color(white);

  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
  }
}

.title {
  margin: 24px;
  margin-bottom: 22px;
}

.unavailable {
  height: 141px;
  padding: 24px;
  justify-content: left;
  align-items: center;
}

.unavailableBoleto {
  padding: 24px 24px 24px 24px;
  justify-content: left;
  align-items: center;
}

.preinstallBoleto {
  display: flex;
  flex: 1;
  padding: 0px 0px 24px 24px;
}
