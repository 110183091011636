@use '@mfe/shared-css/global' as *;

.outcome {
  position: relative;
  @include query-below($smMax) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 64px 80px 32px 80px;

  @include query-below($smMax) {
    background-color: color(white);
    margin: 0;
    padding: 16px;
    flex: 1;
  }
}

.text-container {
  @include query-below($smMax) {
    padding-inline: 16px;
  }
}

.footer {
  background-color: color(gray_100);
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  padding: 32px;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid color(gray_200);

  button:only-child {
    margin: auto;
  }

  @include query-below($smMax) {
    background-color: color(gray_100);
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    padding: 24px 16px;
    button {
      max-width: 343px;
    }
  }
}

.close {
  position: absolute;
  background-color: color(white);
  border: none;
  border-radius: 100%;
  right: 0;
  top: 0;
  margin: 28px;
  cursor: pointer;
  @include query-below($smMax) {
    margin: 32px 24px 0 0;
  }
}

.go-to-national-verifier-link {
  display: block;
  width: 100%;
  padding: 8px 24px;
  text-decoration: none;
}
