@use '@mfe/shared-css/global' as *;

.customer-support-tel-link {
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}

.back-button {
  @include query-below($smMax) {
    max-width: 400px;
  }
}
