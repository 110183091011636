@use '@mfe/shared-css/global' as *;

.container {
  display: block;
  border-width: 0;
  border-color: color(gray_200);
  border-style: solid;
  border-radius: 16px;
  overflow: hidden;
  background-color: color(white);
  @include screenSize('small') {
    border-radius: 0;
  }
}
