@use '@mfe/shared-css/global' as *;

.surface {
  display: flex;
  flex-direction: column;
  background-color: color(white);

  @include query-below($smMax) {
    border-radius: 0;
    width: 100%;
  }

  @include query-above($mdMin) {
    border-radius: 16px;
    width: 600px;
    min-height: 448px;
    max-height: 700px;
    overflow-x: hidden;
  }

  *:focus {
    outline: none;
  }

  div {
    display: block;
  }
}
