.sections {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.subsections-list {
  list-style-type: lower-alpha;
  margin: 0;
}

.subsections-sub-list {
  list-style-type: lower-roman;
}
