@use '@mfe/shared-css/global' as *;

.calendar {
  width: 100%;
  text-align: center;

  .calendar-title {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
  }

  .calendar-month {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & + .calendar-month {
      margin-top: 40px;
    }

    .calendar-month-title {
      padding: 8px;
      border-radius: 8px;
      text-transform: capitalize;
      background-color: color(gray_100);
    }

    .calendar-week-days,
    .calendar-body-days {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(7, 1fr);
    }

    .calendar-week-day {
      min-width: 32px;
      padding-block: 8px;
      text-transform: capitalize;
    }

    .calendar-body-day {
      border: none;
      min-width: 32px;
      padding-block: 8px;
      border-radius: 4px;
      background-color: color(transparent);
      color: color(gray_800);

      &.calendar-body-day-available {
        cursor: pointer;
        background-color: color(blue_100);
      }

      &.calendar-body-day-today {
        background-color: color(gray_100);
        position: relative;
        &:after {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: color(gray_1000);
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.calendar-body-day-selected {
        color: color(white);
        background-color: color(gray_1000);
      }
    }
    /* query applies the hover effect only on devices that support hover interactions */
    @media (hover: hover) {
      .calendar-body-day-available:hover {
        background-color: color(blue_200);
      }
    }
  }
}

.calendarInfo {
  display: flex;
  align-items: center;
  gap: 24px;
}
