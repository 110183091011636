@use '@mfe/shared-css/global' as *;

.form > * + * {
  margin-top: 24px;
}

.dob-field-wrapper {
  width: 100%;
}

.display-none {
  display: none;
}

.dob-selectors {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  @include query-below($smMax) {
    flex-direction: column;
    gap: 24px;
  }
}

div.helper-text {
  margin-top: 8px;
  align-items: flex-start;
  flex-wrap: nowrap;

  svg {
    margin-top: 2px;
  }
}

.radio-field-wrapper {
  margin-top: 24px;
}

.radio-field {
  padding: 8px 0 0;
}

.footer {
  padding: 24px;
  display: flex;
  gap: 16px;

  @include query-below($smMax) {
    align-items: center;
    flex-direction: column-reverse;
  }

  @include query-above($smMax) {
    justify-content: flex-end;
    border-top: 1px solid color(gray_200);
  }
}

.terms {
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: color(gray_100);

  div[class^='beam-helper-text'] {
    margin-top: 8px;
    margin-left: 8px;
  }
}

.checkbox {
  display: flex;
  align-items: flex-start;
}
