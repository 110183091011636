@use '@mfe/shared-css/global' as *;

.wrapper {
  background: color('white');
  color: color(gray_600);
  padding: 24px 16px;

  &:not(:first-child) {
    border-top: 1px solid color(gray_200);
  }

  @media only screen and (min-width: 375px) {
    padding-inline: 24px;
  }

  @media only screen and (min-width: 768px) {
    padding: 80px 32px;
  }
}

.container {
  max-width: 1248px;
  margin-inline: auto;

  > * + * {
    margin-top: 24px;
  }
}

.textLinePlaceholder {
  display: block;
  width: 100%;
  height: 1em;

  border-radius: 4px;

  @include shimmerAnimate;

  & + & {
    margin-top: 0.5em;
  }

  &:last-child:not(:first-child) {
    max-width: 15em;
  }
}
