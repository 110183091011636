@use '@mfe/shared-css/global' as *;

.buttonsWrapper {
  display: flex;
  gap: 16px;

  @include screenSize('small') {
    flex-direction: column;
  }
}

.alertsLink {
  display: flex;
  align-items: center;
}

.alerts-section {
  :global(.beam-page-alert__description) {
    display: block;
  }

  :global(.beam-page-alert__description):has(
      + :global(.beam-page-alert__actions):empty
    ) {
    margin-bottom: 0;
  }

  @include screenSize('small') {
    :global(.beam-page-alert__content) {
      margin: 0 8px 0 8px;
    }
  }
}
