@use '@mfe/shared-css/global' as *;

.container {
  margin: 0px 48px 40px 48px;
  display: flex;
  gap: 8px;

  @include screenSize('small') {
    margin: 0px;
    margin-bottom: 40px;
  }
}
