@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-width: 824px;
  background-color: color(white);
  border-radius: 16px;

  @media screen and (max-width: $lgMin) {
    max-width: 100%;
  }

  @media screen and (max-width: $smMax) {
    border-radius: 0;
    min-width: 320px;
  }
}

.pageLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: $mdMax) {
    flex-direction: column;
  }
}

.bannerContainer {
  max-width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $xsMax) {
    padding-top: 0;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  padding-top: 100px;
}

.scrubSpinnerContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 400px;
  justify-content: center;
  border-radius: 16px;
  @include screenSize('medium') {
    padding: 80px 0;
    height: auto;
  }
  @include screenSize('small') {
    border-radius: 0;
  }
}
