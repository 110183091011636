@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.box {
  display: flex;
  flex-direction: column;

  width: 81px;
  height: 56px;
  padding: 4px 8px;

  background-color: color(gray_100);
  border-radius: 8px;
}
