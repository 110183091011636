@use '@mfe/shared-css/global' as *;

.container {
  grid-column: 2;
  grid-row: 3;

  @include screenSize('medium') {
    grid-column: 1;
    grid-row: 3;
  }

  @include screenSize('small') {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid color(gray_200);
  padding: 18px 24px;
}

.footer {
  border-top: 1px solid color(gray_200);
  padding: 18px 24px;
}

.no-add-ons-container {
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;

  @include screenSize('small') {
    padding: 24px;
  }

  @include screenSize('extraSmall') {
    padding: 24px 16px;
  }
}

.profile-loading-container {
  border-radius: 16px;
  @include screenSize('small') {
    border-radius: 0px;
  }

  > div:not(:last-child) {
    border-bottom: 1px solid color(gray_200);
  }

  .loading-title-container {
    padding: 24px;

    .loading-title {
      width: 160px;
      height: 28px;
      @include shimmerAnimate;
      border-radius: 4px;
    }
  }

  .loading-content {
    padding: 24px;
    display: flex;
    gap: 16px;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      @include shimmerAnimate;
    }

    .text-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .title {
        width: 100%;
        height: 24px;
        @include shimmerAnimate;
        border-radius: 4px;
      }
      .desc {
        width: 100%;
        height: 40px;
        @include shimmerAnimate;
        border-radius: 4px;
      }
      .price {
        width: 100%;
        height: 24px;
        @include shimmerAnimate;
        border-radius: 4px;
      }
    }
  }
}

.addons__item {
  padding: 24px;

  & + .addons__item {
    border-top: 1px solid color(gray_200);
  }
}

.pricesAndDiscounts {
  display: flex;
  align-items: center;
}
