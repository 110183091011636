@use '@mfe/shared-css/global' as *;

.headerMargin {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid color(gray_200);
  margin-bottom: 24px;

  @include screenSize('small') {
    padding: 32px 24px;
    border-bottom: none;
    background: white;
  }
  @include screenSize('extraSmall') {
    padding: 32px 16px;
  }
}

$XLargeImageWidth: 370px;
$MediumImageWidth: 300px;
$SmallImageWidth: 142px;

.headerTextContainer {
  width: 558px;
  display: flex;
  flex-direction: column;
  margin: auto auto auto 0;

  @media screen and (max-width: $mdMax) {
    width: calc(100% - #{$MediumImageWidth});
  }

  @media screen and (max-width: $smMax) {
    width: calc(100% - #{$SmallImageWidth});
  }
}

.imageContainer {
  display: flex;
  flex-direction: row;
}

.smallHeaderMargin {
  width: 100%;
  min-width: 320px;
  margin-bottom: 40px;
  @include screenSize('small') {
    background-color: white;
    padding: 16px;
    margin-bottom: 24px;
  }
}

.smallHeaderMargin,
.smallHeaderMargin * {
  box-sizing: border-box;
}

.loadingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  @include screenSize('small') {
    display: inline;
  }
}

.loading {
  @include shimmerAnimate;
  width: 200px;
  height: 24px;
  border-radius: 5px;
}
