@use '@mfe/shared-css/global' as *;

.card {
  box-sizing: border-box;
  width: 400px;
  padding: 24px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.12);
  position: relative;
  background-color: color(white);

  &:before {
    content: '';
    height: 8px;
    width: 100%;
    background: linear-gradient(90deg, color(blue_700), color(teal_500));
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  @include screenSize('medium') {
    max-width: none;
    width: 100%;
  }

  @include screenSize('small') {
    border-radius: 0;
    padding: 24px 16px;
  }

  @include screenSize('extraSmall') {
    min-width: fit-content;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.titleAndDescription,
.buttons {
  text-align: center;
}

.dateAndLink {
  align-self: flex-start;
  display: flex;
  gap: 8px;
}

.iconLoading,
.titleLoading,
.descriptionLoading {
  @include shimmerAnimate;
  border-radius: 4px;
}

.iconLoading {
  width: 100%;
  height: 100px;
  margin-bottom: 32px;
}

.titleLoading {
  width: 100%;
  height: 28px;
  margin-bottom: 6px;
}

.descriptionLoading {
  width: 100%;
  height: 28px;
}

.wifiAnimation {
  width: 100px;
  height: 100px;

  .path1 {
    animation: path1 4s infinite;
  }

  .path2 {
    animation: path2 4s infinite;
  }

  .path3 {
    animation: path3 4s infinite;
  }
}

@keyframes path1 {
  0%,
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes path2 {
  0%,
  50% {
    opacity: 0;
  }
  75%,
  100% {
    opacity: 1;
  }
}

@keyframes path3 {
  0%,
  25% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}
