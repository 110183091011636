@use '@mfe/shared-css/global' as *;

.wrapper {
  background: color('white');
  padding: 8px 16px;

  @media only screen and (min-width: 375px) {
    padding-inline: 24px;
  }

  @media only screen and (min-width: 768px) {
    padding: 40px 32px;
  }
}

.container {
  max-width: 1248px;
  margin-inline: auto;

  :global {
    .beam-accordion[open] .beam-accordion__content {
      max-height: min-content;
    }
  }
}

.addons-wrapper {
  display: grid;
  gap: 16px;

  @media only screen and (min-width: 768px) {
    gap: 40px;
  }
}

.addons,
.loading-subscribed-addons {
  display: grid;
  gap: 16px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.addons__item {
  border: 1px solid color(gray_200);
  border-radius: 8px;
  padding: 24px;
}

.manage-addons-link {
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
  }
}

// Loading
.loading-card {
  border: 1px solid color(gray_200);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2;
}

.loading-icon {
  @include shimmerAnimate;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.loading-title,
.loading-description {
  @include shimmerAnimate;
  height: 24px;
  border-radius: 4px;
}

.loading-title {
  width: 50%;
}

.loading-description {
  width: 100%;
}

// Error
.subscribed-addons-error {
  // Low-key abomination 😮‍💨
  & > div {
    border-radius: 0;
    & > div:first-child {
      border-bottom: 1px solid color(gray_200);
      & > div {
        max-width: 1248px;
        margin-inline: auto;
        border: none;
        @include query-above(1300px) {
          padding-inline: 0;
        }
      }
    }
  }
}
