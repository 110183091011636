@use '@mfe/shared-css/global' as *;

.titleContainer {
  display: flex;
  gap: 8px;
  padding: 24px;
  background-color: color(white);
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid color(gray_200);

  @media screen and (max-width: $xsMax) {
    padding: 24px 16px;
  }
}
