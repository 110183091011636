@use '@mfe/shared-css/global' as *;

.add-on-card-container {
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 16px;
}

.add-on-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-and-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  flex-wrap: wrap;
}

.icon-container {
  padding: 8px;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  white-space: nowrap;
}

.addons-section {
  display: flex;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid color(gray_200);
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;

  & :first-child {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.support-options-container {
  display: flex;
  gap: 24px;

  .support-option-item {
    border: 1px solid color(gray_200);

    span {
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  @include query-below($smMax) {
    display: block;
    .support-option-item:first-child {
      margin-bottom: 16px;
    }
  }
}
