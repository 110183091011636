@use '@mfe/shared-css/global' as *;

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 0 0 16px 16px;
  padding: 0 48px 16px 48px;

  @include query-below($smMax) {
    padding: 0 24px 16px 24px;
  }

  @include query-below($xsMax) {
    padding: 0 16px 16px 16px;
  }
}
