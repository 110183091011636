@use '@mfe/shared-css/global' as *;

.offsetElement {
  height: $mobileTabBarHeight;
}

.tabBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12346;

  height: $mobileTabBarHeight;
  background-color: white;
  border-top: 1px solid color(gray_300);

  a {
    text-decoration: none;
  }
}

.tabItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  p,
  span {
    margin-top: 2px;
    text-align: center;
  }

  p,
  span,
  svg {
    color: color(gray_500);
  }

  &.active {
    p,
    span,
    svg {
      color: color(teal_500);
    }
  }
}
