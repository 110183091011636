@use '@mfe/shared-css/global' as *;

.modal-content {
  p {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.action-cards {
  display: flex;
  gap: 16px;
  @include query-below($smMax) {
    flex-direction: column;
  }
}

.action-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid color(gray_200);
}

.primary-action,
.secondary-action {
  span {
    margin-right: 4px;
  }
}

.primary-action {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  // Arrow
  [class*='beam-button-link__arrow-forward-container'] {
    display: none;
  }
}

.secondary-action {
  span {
    margin-right: 4px;
  }
  a {
    text-decoration: none;
  }
}
