.order-details-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.noDataRemaining {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dataRemaining {
  & > * + * {
    margin-left: 4px;
  }
}

.title-with-badge {
  display: flex;
  align-items: center;
  gap: 4px;
}

.capitalize {
  span:first-letter {
    text-transform: capitalize;
  }
}
