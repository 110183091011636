@use '@mfe/shared-css/global' as *;

.footer {
  display: flex;
  flex: 1;
}

.paginationContainer {
  display: flex;
  height: 72px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid color(gray_200);
}

.arrow {
  background-color: transparent;
  border: none;
}

.disabledArrow {
  margin: 8px;
  color: color(gray_200) !important;
}

.activeArrow {
  margin: 8px;
  color: color(gray_600) !important;
}

.ellipses {
  margin: 10px;
}

.grayElt {
  margin: 4px;
  color: color(gray_600) !important;
}

.showMoreButton {
  background-color: transparent;
  border: none;

  & > p {
    cursor: pointer;
    color: color(blue_600) !important;
    text-decoration: underline;
  }
}

.showMoreText {
  margin: 24px;
  color: color(blue_600) !important;
  text-decoration: underline;
}

.pageNumber {
  color: color(blue_600) !important;
  margin: 4px;
  text-decoration: underline;
}
