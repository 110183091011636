@use '@mfe/shared-css/global' as *;

.wrapper {
  padding: 24px;
  margin-inline: auto;

  @media only screen and (min-width: 768px) {
    padding: 0 32px 40px;
  }
}

.spinnerWrapper {
  padding-block: 80px;
}

.grid {
  display: grid;
  grid-template-columns: minmax(auto, 400px);
  justify-content: center;
  gap: 24px;

  width: 100%;
  max-width: 1248px;
  margin-inline: auto;

  &:not(:has(> :first-child:last-child)) {
    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(auto, 400px));
      row-gap: 32px;
    }

    &:not(:has(> :first-child:nth-last-child(2))) {
      @media only screen and (min-width: 1301px) {
        grid-template-columns: repeat(3, minmax(auto, 400px));
      }
    }
  }
}

.gridItem {
  max-width: 400px;
  padding: 24px;
  background: color(white);
  color: color(gray_800);
  border-radius: 16px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  gap: 24px;
}

.gridItem__image {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  border-radius: 4px;
}

.gridItem__image--loading {
  @include shimmerAnimate;
}

.gridItem__header {
  > * + * {
    margin-top: 8px;
  }
}

.gridItem__pricesAndPromotions {
  align-self: center;

  > * + * {
    margin-top: 8px;
  }
}

.gridItem__prices {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 8px;

  span {
    line-height: 24px;
  }
}

.gridItem__title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.gridItem__title-text:only-child {
  flex-grow: 1;
}

.gridItem__price {
  display: flex;
  align-items: baseline;

  > :only-child {
    flex-grow: 1;
    max-width: 5em;
  }
}

.gridItem__price-decimals {
  font-weight: inherit !important;
  transform: translateY(-32%);
  display: inline-block;
}

.gridItem__promotion {
  display: flex;
  align-items: center;
  gap: 8px;
}

.empty-state-illustration {
  @include query-below($smMax) {
    width: 100%;
  }
}
