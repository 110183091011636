@use '@mfe/shared-css/global' as *;

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: color(white);
  min-width: 320px;
  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
  }
}

.solidLine {
  border-top: 1px solid color(gray_200);
}

.summaryHeader {
  @include shimmerAnimate;
  align-self: center;
  width: 175px;
  height: 16px;
  border-radius: 5px;
  margin: 36px 24px 24px 24px;
}

.billingStatement {
  @extend .summaryHeader;
  align-self: flex-start;
}

.tabTitles {
  @include shimmerAnimate;
  align-self: center;
  width: 275px;
  height: 20px;
  border-radius: 5px;
  margin: 24px;
}

.detail {
  @include shimmerAnimate;
  width: 200px;
  height: 20px;
  border-radius: 5px;
  margin: 12px 24px 20px 24px;
}

.payment {
  @include shimmerAnimate;
  width: 225px;
  height: 20px;
  border-radius: 5px;
  margin: 0 24px 5px 24px;
}

.date {
  @include shimmerAnimate;
  width: 275px;
  height: 20px;
  border-radius: 5px;
  margin: 0 24px 24px 24px;
}
