@use '@mfe/shared-css/global' as *;

.form hr {
  height: 1px;
  width: 100%;
  border: none;
  background-color: color(gray_200);
  margin: 0;
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  padding: 0 48px 16px 48px;
  @include query-below($smMax) {
    padding: 0 24px 16px 24px;
  }
  @include query-below($xsMax) {
    padding: 0 16px 16px 16px;
  }
}

.form-footer {
  border-radius: 0 0 16px 16px;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @include query-below($smMax) {
    flex-direction: column-reverse;
    align-items: center;
    border-radius: 0;
    button {
      max-width: 400px;
    }
  }
  @include query-below($xsMax) {
    padding: 24px 16px;
    flex-direction: column-reverse;
    align-items: center;
  }
}
