@use '@mfe/shared-css/global' as *;

.addOnSection {
  width: 100%;
  @include screenSize('medium') {
    display: flex;
    flex-direction: row;
  }
  @include screenSize('small') {
    padding: 16px 0 24px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
    background-color: color(white);
  }
}

.addOnCardList {
  display: flex;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }
  @include screenSize('medium') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @include screenSize('small') {
    justify-content: flex-start;
    overflow-x: auto;
    padding: 8px 24px;
  }

  @include screenSize('extraSmall') {
    width: 100vw;
  }
}

// AddOnCard
.addOnCardMargin {
  display: flex;
  width: 100%;
  &:not(:first-child) {
    margin-left: 24px;
  }
  @include screenSize('small') {
    width: 100%;
    min-width: 250px;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.addOnCard {
  background: color(blue_100);
  border: 1px solid color(blue_200);
  padding: 24px;
  border-radius: 16px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include screenSize('medium') {
    max-width: none;
  }
}

.addOnText {
  display: none;
  @include screenSize('small') {
    display: block;
    margin: 24px;
  }
}

.addOnCardIcon {
  margin-right: 16px;
}

.addOnCardTitle {
  display: flex;
  align-items: flex-end;
}

.addOnCardTitleText {
  display: flex;
  align-items: flex-end;
}

.addOnCardCaption {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}

.addOnCardLink {
  display: flex;
  margin-top: auto;
  border: 1px solid color(black);
  border-radius: 100px;
  width: 104px;
  padding: 6px;
  cursor: pointer;
  justify-content: center;

  > a {
    cursor: pointer;
  }
}
