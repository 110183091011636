@use 'global' as *;

.tabContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include screenSize('medium') {
    width: 100%;
  }

  @media only screen and (min-width: 992px) {
    flex-basis: min-content;
  }

  @media only screen and (min-width: 1100px) {
    flex-basis: auto;
  }
}

.activeTab {
  display: flex;
  height: 3px;
  width: 100%;
  background-color: color(teal_600);
  @include screenSize('medium') {
    display: none;
  }
}
.inactiveTab {
  display: flex;
  height: 2px;
  width: 100%;
  background-color: color(white);
  @include screenSize('medium') {
    display: none;
  }
}

.navItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: color(gray_500);
  text-decoration: none;
  margin: auto;
  padding: 6px 12px;

  &:focus-visible {
    outline: 2px solid color(blue_500) !important;
    outline-offset: -4px;
    border-radius: 8px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:hover {
    color: color(gray_600);
  }

  &.selected {
    outline: 0 !important;
  }

  @include screenSize('medium') {
    height: 63px;
    justify-content: flex-start;
    border-bottom: 1px solid color(gray_200);
    color: color(gray_500);
    margin: 0;
    padding: 0;

    &:hover {
      color: color(gray_500);
    }

    &.selected {
      p,
      span {
        font-weight: 500;
      }

      border-bottom: 1px solid color(gray_200);
    }
  }
}
