@use '@mfe/shared-css/global' as *;

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 216px 0px;
  border-radius: 0 0 16px 16px;
}
