@use '@mfe/shared-css/global' as *;

.hamburgerButton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  min-width: 72px;
  color: color('gray_700');
  padding-right: 32px;
  &:hover {
    color: color('teal_600');
  }

  @include screenSize('small') {
    .menuText {
      font-size: 16px;
    }
  }

  @include screenSize('extraSmall') {
    min-width: 0;

    .menuText {
      display: none;
    }
  }
}
