@use '@mfe/shared-css/global' as *;

%averageUsage {
  width: 165px;
  height: 24px;
  margin-top: 11px;
  border-radius: 4px;
}

.billCycleNavigatorLoading {
  width: 215px;
  height: 40px;
  border-radius: 4px;
  @include shimmerAnimate;
}

.averageUsageLoading {
  @extend %averageUsage;
  @include shimmerAnimate;
}

.freeZoneTimeLoading {
  width: 50%;
  height: 24px;
  border-radius: 4px;
  @include shimmerAnimate;
}

@media screen and (min-width: $mdMin) {
  .averageUsageLoading {
    @extend %averageUsage;
    @include shimmerAnimate;
    margin-top: 24px;
    margin-right: auto;
  }
}

.chartLoadingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: white;
  margin-bottom: 24px;
  @include query-below($smMax) {
    margin-bottom: 8px;
  }
}

.chartLoadingAnimation {
  display: flex;
  background-color: white;
  border-radius: 0px 0px 16px 16px;
  @include query-below($smMax) {
    border-radius: 0px;
  }
}

.chartLoadingTitle {
  background-color: white;
  border-bottom: 1px solid color(gray_100);
  border-radius: 16px 16px 0px 0px;
  padding: 24px;
  @include query-below($smMax) {
    border-radius: 0px;
  }
  @include query-below($xsMax) {
    padding: 16px;
  }
}
