@use '@mfe/shared-css/global' as *;

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.loadingCell {
  width: 100%;
  min-width: 300px;
  height: 32px;
  @include shimmerAnimate;
  border-radius: 8px;
  position: relative;
}

.loadingCell::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: color(gray_200);
  bottom: -16px;
}

.loadingTitle {
  width: 100%;
  max-width: 200px;
  height: 32px;
  @include shimmerAnimate;
  border-radius: 8px;
  margin-bottom: -16px;
}

.loadingButton {
  width: 100%;
  min-width: 300px;
  height: 40px;
  @include shimmerAnimate;
  border-radius: 1000px;
  margin-top: 8px;
}

.commonQuestionsError {
  margin: 0px -24px;
  @include screenSize('medium') {
    border-radius: 0px;
  }
}
