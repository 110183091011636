.pageContent {
  flex: 1;
  display: flex;
  align-items: center;
}

.modemAnimation {
  width: 100px;
  height: 100px;

  .path1 {
    animation: path1 4s infinite;
  }

  .path2 {
    animation: path2 4s infinite;
  }

  .path3 {
    animation: path3 4s infinite;
  }
}

@keyframes path1 {
  0%,
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes path2 {
  0%,
  50% {
    opacity: 0;
  }
  75%,
  100% {
    opacity: 1;
  }
}

@keyframes path3 {
  0%,
  25% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}
