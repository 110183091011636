@use '@mfe/shared-css/global' as *;

.cart-summary {
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  @include query-below($mdMin) {
    border-bottom: 1px solid color(gray_200);
  }

  hr {
    height: 1px;
    width: 100%;
    border: none;
    background-color: color(gray_200);
    margin: 0;
  }
}

.download-speed-container {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.text-container {
  display: flex;
  gap: 4px;
}
