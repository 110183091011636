@use '@mfe/shared-css/global' as *;

.time {
  width: 100%;
  gap: 12px;
}

.time-interval-container {
  display: flex;
  gap: 16px;

  @include query-below($smMax) {
    flex-direction: column;
  }
}

.time-interval {
  background-color: color(transparent);
  color: color(gray_800);
  border: 1px solid color(gray_1000);
}

.time-interval-selected {
  color: color(white);
  background-color: color(gray_1000);
}
