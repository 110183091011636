@use '@vst/beam/sass/utils/colors';
@use 'sass:map';

.labelTitle {
  @media screen and (max-width: 374px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.addressSubtitle {
  margin-top: 8px;
  margin-left: 28px;
  max-width: fit-content;

  @media screen and (max-width: 374px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.containerAddressButtons {
  margin-bottom: 24px;
  border-radius: 8px;
  height: auto;
  width: 100%;
}

.containerAddressButtonsChecked {
  background-color: rgba(24, 236, 252, 0.1);
  border: 1px solid map-get($map: colors.$raw-colors, $key: 'teal_500');

  @media screen and (max-width: 374px) {
    margin-bottom: 12px;
  }
}

.containerAddressButtonsUnChecked {
  background-color: map-get($map: colors.$raw-colors, $key: 'gray_100');
  border: 1px solid map-get($map: colors.$raw-colors, $key: 'gray_200');

  @media screen and (max-width: 374px) {
    margin-bottom: 12px;
  }
}

.radioButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
}

.radioButton {
  display: flex;
}

.radio {
  padding: 0px;
}

.wrapper {
  margin: 20px;
}
