@use '@mfe/shared-css/global' as *;

.modal {
  overflow-y: auto;
  min-width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin: 64px 0;
  min-width: 320px;
  background-color: white;
  border-radius: 16px;
  width: 600px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 30px 2px rgba(0, 0, 0, 0.15);
  @include screenSize('small') {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.exit {
  margin: 20px 20px auto auto;
  background-color: white;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  cursor: pointer;
  padding-left: 4px;
  @include screenSize('small') {
    margin: 16px 16px 0 auto;
  }
  &:hover {
    background-color: color(gray_300);
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 52px;

  [class*='beam-empty-state__outer'] {
    display: flex;
    align-items: center;
    height: 424px;
    min-width: 320px;
    padding: 0 48px;

    @include screenSize('small') {
      padding: 0 24px;
    }

    svg {
      color: color(black);
    }
  }
}
