@use '@mfe/shared-css/global' as *;

.profile-button-container {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
  padding: 26px 16px;
  background-color: transparent;
}

.profile-logo {
  justify-self: center;
  margin-left: 15%;
}

.profile-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@include screenSize('small') {
  .profile-button-container {
    background-color: color(white);
  }
}
