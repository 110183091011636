@use '@mfe/shared-css/global' as *;

.page-header {
  margin-bottom: 8px;
  margin-top: 0px;

  @include query-above($mdMin) {
    margin-block: 40px;
  }
}

.page-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;

  max-width: 1248px;

  margin: 0 auto 72px;
  @include query-below(1330px) {
    margin: 0 32px 72px;
  }

  @include query-below($mdMax) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include query-below($smMax) {
    margin: 0 0 8px;
    gap: 8px;
  }
}

.order-review-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details-content {
  padding: 48px;

  & > *:not(:last-child) {
    padding-bottom: 40px;
  }

  & > * + * {
    border-top: 1px solid color(gray_200);
    padding-top: 40px;
  }

  @include query-below($smMax) {
    padding: 24px;
  }
  @include query-below($xsMax) {
    padding: 16px;
  }
}

.details-footer {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid color(gray_200);
  button {
    width: 223px;
  }

  @include query-below($mdMax) {
    justify-content: center;
    button {
      max-width: 400px;
    }
  }
}

.spinner-wrapper {
  padding: 80px 0;
  margin: auto 0;

  @media only screen and (min-width: 768px) {
    padding: 136px 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  > .page-header {
    width: 100%;
  }
}
