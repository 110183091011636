@use '@mfe/shared-css/global' as *;

.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: $mdMin) {
    border-radius: 16px;
  }

  @media screen and (max-width: $lgMin) {
    max-width: initial;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.badgeContainer {
  align-self: center;
  margin-bottom: 16px;
}

.headerContainer {
  padding: 32px 24px 24px 24px;
  & > p {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    color: color(gray_800);
    text-align: center;
  }
  @media screen and (max-width: $smMin) {
    padding: 20px 15px;
  }
  .daysDue {
    padding: 3px;
  }
  .nextBillDate {
    padding: 3px;
  }
}

.balanceContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.autoPayContainer {
  padding: 0px 24px;
}

.paymentButtonContainer {
  padding: 24px;
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.largeLoading {
  @include shimmerAnimate;
  min-width: 352px;
  width: 100%;
  height: 36px;
  border-radius: 5px;
}

.mediumLoading {
  @include shimmerAnimate;
  max-width: 225px;
  width: 100%;
  height: 24px;
  border-radius: 5px;
}

.smallLoading {
  @include shimmerAnimate;
  width: 150px;
  height: 16px;
  border-radius: 5px;
}

.summary {
  padding: 32px 24px 24px 24px;
  & > div + div {
    margin-top: 16px;
  }
}

.loadingContainer {
  border-radius: 16px;
  overflow: hidden;
  background-color: color(white);
  max-width: 400px;
  min-width: 320px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  @include screenSize('medium') {
    max-width: none;
  }
  @include screenSize('small') {
    border-radius: 0;
  }
}

.link {
  padding: 24px;
  border-top: 1px solid color(gray_200);

  @include screenSize('small') {
    padding: 20px 24px;
  }
}
