@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  max-width: 400px;
  min-width: 256px;
  height: fit-content;

  & .header {
    text-align: center;
  }
  & .body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    flex-grow: 1;
    justify-content: space-between;
    @include query-below($mdMin) {
      padding: 24px 16px;
    }
  }
  & .data-section {
    display: flex;
    text-align: center;
  }

  &.default {
    & .header {
      background-color: color(blue_100);
      color: color(blue_700);
    }

    & .data-section h3,
    .data-section h4 {
      color: color(blue_700);
    }
  }
  &.blue {
    border: 1px solid color(blue_700);
    & .header {
      background-color: color(blue_700);
      color: color(white);
    }
    @include query-below($mdMax) {
      border: none;
      width: 100%;
      height: 100%;
      background-color: color(white);
      border-radius: 0;
      margin: 0 auto;
      & .header {
        display: none;
      }
    }
  }
  .overlay {
    height: 88px;
    width: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(
      0deg,
      #fff 4.03%,
      rgba(255, 255, 255, 0) 67.35%
    );
  }
}
