@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  padding: 24px;

  @include screenSize('small') {
    border-radius: 0px;
  }
}

.header {
  display: grid;
  gap: 32px;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }
}

.addons-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  &.single-column {
    grid-template-columns: 1fr;
  }
  @include screenSize('large') {
    display: flex;
    flex-direction: column;
  }
  @include screenSize('medium') {
    display: grid;
  }
  @include screenSize('small') {
    display: flex;
    flex-direction: column;
  }
}

.header-buttons {
  display: grid;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  margin-inline: auto;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-inline-end: 0;
  }
}

.addons-section {
  display: flex;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;

  & :first-child {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  @include screenSize('small') {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.icon-container {
  padding: 8px;
  border-radius: 4px;
  width: 40px;
}

.no-addons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 48px 0;
  text-align: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px;
  gap: 24px;

  & > .loading-title {
    width: 25%;
    min-width: 300px;
    height: 28px;
    @include shimmerAnimate;
    border-radius: 8px;
  }

  & > .loading-addon-section {
    display: flex;
    gap: 8px;
    border: 1px solid #e5e5e5;
    min-width: 300px;
    border-radius: 8px;
    padding: 16px;
    align-items: center;

    & .icon {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      @include shimmerAnimate;
    }

    & .name {
      width: 25%;
      height: 28px;
      border-radius: 8px;
      @include shimmerAnimate;
    }

    & .desc {
      width: 15%;
      height: 28px;
      border-radius: 8px;
      @include shimmerAnimate;
    }
  }

  & > .loading-footer {
    width: 70%;
    height: 28px;
    border-radius: 8px;
    @include shimmerAnimate;
  }
}

.addons__item {
  padding: 24px;

  & + .addons__item {
    border-top: 1px solid color(gray_200);
  }
}

.addonCard {
  border: 1px solid color(gray_200);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  & > div {
    align-items: center;
  }
}
