@use '@mfe/shared-css/global' as *;

.title {
  margin-block: 64px 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  @include query-below($smMax) {
    margin-block: 16px;
  }
}

.leftCardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px;
  @include query-below($smMax) {
    padding: 24px;
  }
  @include query-below($xsMax) {
    padding: 16px;
  }

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.sectionAlert {
  & > div {
    display: block;
  }

  span:first-of-type {
    margin-right: 4px;
  }

  [class*='beam-section-alert__content'] {
    display: flex;
  }
}

.customerSupportTelLink {
  white-space: nowrap;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: color(gray_200);
  border: none;
}

.contactInformation {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contactInfoCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.editActions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.editAddressForm {
  margin-block: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }
}

.editAddressFormGroup {
  @include query-below($smMax) {
    display: flex;
    flex-direction: column;
  }
  @include query-between($lgMin, $lgMax) {
    display: flex;
    flex-direction: column;
  }
}

.emailForm {
  margin-block: 16px 32px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @include query-below($smMax) {
    flex-direction: column-reverse;
    align-items: center;
    button {
      max-width: 400px;
      width: 100%;
    }
  }

  padding: 24px;
  border-top: 1px solid color(gray_200);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @include query-above($smMax) {
    [class*='beam-btn--primary'] {
      min-width: 224px;
    }
  }
}
