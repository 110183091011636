@use '@mfe/shared-css/global' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  max-width: 612px;

  @include query-below($xlMin) {
    max-width: 714px;
  }

  @include query-below($lgMin) {
    max-width: 522px;
  }

  @include query-below($smMax) {
    max-width: 100%;
    height: 100%;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.backToMyViasat {
  max-width: 190px;

  @include query-below($smMax) {
    max-width: 400px;
  }
}
