@use '@mfe/shared-css/global' as *;

.loading-scrub-address-modal {
  width: 600px;
  height: auto;
  min-height: unset;
  height: 240px;
  :global {
    .beam-modal__wrap-actions {
      border-top: none;
    }
  }

  @include query-above($smMax) {
    margin-top: 12px;
  }

  @include query-below($smMax) {
    width: 100%;
    height: auto;
  }

  p,
  [class*='beam-modal__body'] {
    padding: 0;
    margin: 0;
  }

  [class*='beam-spinner-wrap'] {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
  }

  [class*='beam-spinner'] {
    margin-bottom: 0;
  }
}

.modal-body {
  text-align: center;
}

.incorrect-address-modal {
  min-height: unset;
}
