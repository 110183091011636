@use '@mfe/shared-css/global' as *;

$navHeight: 76px;

.offsetElement {
  height: $navHeight;
}

.logoAndTabsContainer {
  display: flex;
  flex-direction: row;
}

.languageToggleAndProfileContainer {
  display: flex;
  flex-direction: row;
}

.logoContainer {
  display: flex;
  height: 100%;
  margin: auto 0;
}

.dividerContainer {
  display: flex;
  align-items: center;
  padding: 24px;
}

.divider {
  display: flex;
  height: 24px;
  width: 1px;
  align-items: center;
  background-color: color(gray_200);
}

.navContainer {
  position: fixed;
  height: $navHeight;
  z-index: 2;
  width: 100%;
  padding-inline: 16px;
  background-color: color(white);
  border-bottom: 1px solid color(gray_200);

  &,
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  @media only screen and (min-width: 375px) {
    padding-inline: 24px;
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 32px;
  }
}

.navItemContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  max-width: 1248px;
  margin-inline: auto;

  ul {
    margin: 0;
    padding: 0;
  }
}

.noLanguageToggle {
  @include screenSize('medium') {
    flex: 1;
    justify-content: center;

    //burger button width
    margin-right: 72px;
  }

  @include screenSize('extraSmall') {
    //burger button width
    margin-right: 68px;
  }
}

.logo {
  display: inline-flex;
  align-items: center;

  svg {
    max-width: 135px;
    position: relative;
    bottom: 5px;
  }
}

.tabItemsContainer {
  display: flex;
  gap: 16px;

  @media only screen and (min-width: 1200px) {
    gap: 32px;
  }

  @include screenSize('medium') {
    position: absolute;
    bottom: calc(100vh + $navHeight);
    left: 0;
    height: calc(100vh - $navHeight);
    z-index: 998;
    flex-direction: column;
    background-color: color(white);
    transition: transform 0.25s ease-in-out;
    width: 100%;
    gap: 0;
    padding: 0 24px;
  }

  &.visible {
    transform: translateY(200vh);
  }

  @include screenSize('extraSmall') {
    padding: 0 16px;
  }

  @include query-below($lgMin) {
    min-width: 100vw;
    left: -32px;
  }

  @include query-below($smMax) {
    left: -24px;
  }

  @include query-below(374px) {
    left: -16px;
  }
}

.profileTabContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: color(gray_100);
    border-radius: 8px;
    color: color(gray_600);
  }
}

.profileHoverContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 8px;
  width: 100%;
  gap: 4px;
  &.navItem {
    &:hover {
      color: color(gray_600);
    }
  }
}

.chevron {
  transition: transform 0.2s;

  &.flip {
    transform: rotate(180deg);
  }
}
